import React, { useReducer } from "react";
import axios from "axios";

import estadisticaContext from "./estadisticasContext";
import estadisticaReducer from "./estadisticasReducer";

import {
  ESTADISTICA_ACTUAL,
  ESTADISTICA_TOTAL,
  OPEN_MODAL_ESTADISTICAS,
  CLOSE_MODAL_ESTADISTICAS,
} from "../../types";

const EstadisticaState = (props) => {
  const initialState = {
    ModalEstadisticas: false,
    estadisticas: [{}],
    total: "",
  };

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(estadisticaReducer, initialState);

  const totalEstadistica = (encuestaactual, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE + "vote?pollId=" + encuestaactual,
      timeout: 60000,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: ESTADISTICA_TOTAL,
          payload: response.data,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Selecciona el evento que el usuario dio click
  const estadisticaActual = (encuestaactual, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "vote/count?pollId=" +
        encuestaactual,
      timeout: 60000,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data.result)); */
        dispatch({
          type: ESTADISTICA_ACTUAL,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Abrir el modal de agregar estadisticas
  const openModalEstadisticas = () => {
    dispatch({
      type: OPEN_MODAL_ESTADISTICAS,
    });
  };

  // Cerrar el modal de agregar estadisticas
  const closeModalEstadisticas = () => {
    dispatch({
      type: CLOSE_MODAL_ESTADISTICAS,
    });
  };

  return (
    <estadisticaContext.Provider
      value={{
        ModalEstadisticas: state.ModalEstadisticas,
        estadisticas: state.estadisticas,
        total: state.total,

        estadisticaActual,
        totalEstadistica,
        openModalEstadisticas,
        closeModalEstadisticas,
      }}
    >
      {props.children}
    </estadisticaContext.Provider>
  );
};

export default EstadisticaState;
