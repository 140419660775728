import React, { useReducer } from "react";
import axios from "axios";

import itemContext from "./itemContext";
import ItemReducer from "./itemReducer";

import {
  CARGAR_ITEMS,
  FORMULARIO_ITEMS,
  AGREGAR_ITEM,
  EDITAR_ITEM,
  ELIMINAR_ITEM,
  VALIDAR_FORMULARIO,
  ABRIR_EDICION_ITEM,
  CERRAR_EDICION_ITEM,
  ABRIR_AGREGAR_ITEM,
  CERRAR_AGREGAR_ITEM,
  ITEM_ACTUAL,
  OPEN_MODAL_EDITAR_ITEM,
  CLOSE_MODAL_EDITAR_ITEM,
  OPEN_MODAL_AGREGAR_ITEM,
  CLOSE_MODAL_AGREGAR_ITEM,
  STOP_RELOAD,
} from "../../types/";

const ItemState = (props) => {
  const initialState = {
    items: [{}],
    itemactual: [{}],
    formulario: false,
    errorformulario: false,
    agregaraitem: false,
    editaritem: false,
    openmodal: false,
    ModalAgregarItem: false,
    ModalEditarItem: false,
    actionitem: false,
    loadstatus: false,
  };

  // Crear disptach y state
  const [state, dispatch] = useReducer(ItemReducer, initialState);

  // Serie de funciones para el CRUD
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_ITEMS,
    });
  };

  // Cargar los items
  const cargarItems = (passtoken, itemactual) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "poll-item/?limit=40&order=asc&pollId=" +
        itemactual.id,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(response.data); */
        dispatch({
          type: CARGAR_ITEMS,
          payload: response.data.items,
          status: response.data.status,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });
  };

  // Agregar nuevo item
  const agregarItem = (item, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("label", item.label);
    formdata.append("image", item.imagen);
    formdata.append("pollId", item.iditem);
    formdata.append("weight", item.peso);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_REST_API_SOURCE + "poll-item/create",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error.result));

    // Enviar la data del nuevo item Resetea el formulario
    dispatch({
      type: AGREGAR_ITEM,
    });

    setTimeout(() => {
      // Cerrar modal del formulario de edición
      dispatch({
        type: CLOSE_MODAL_AGREGAR_ITEM,
      });
    }, 1000);
  };

  // Editar item
  const editarItem = (item, passtoken, encuestaId, itemId) => {
    // Se consulta por el token
    const token = passtoken;

    // Insertar el evento en el state
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("label", item.label);
    formdata.append("image", item.imagen);
    formdata.append("pollId", encuestaId);
    formdata.append("weight", item.peso);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_REST_API_SOURCE + "poll-item/update/" + itemId,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error.response.data));

    dispatch({
      type: EDITAR_ITEM,
    });

    setTimeout(() => {
      // Cerrar modal del formulario de edición
      dispatch({
        type: CLOSE_MODAL_EDITAR_ITEM,
      });
    }, 1000);
  };

  // Elimina un item
  const eliminarItem = (itemId, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var config = {
      method: "delete",
      url: process.env.REACT_APP_REST_API_SOURCE + "poll-item/delete/" + itemId,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: ELIMINAR_ITEM,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
    }, 3000);
  };

  // Abrir edicion item
  const abrirEdicionItem = () => {
    dispatch({
      type: ABRIR_EDICION_ITEM,
    });
  };

  // Cerrar edicion item
  const cerrarEdicionItem = () => {
    dispatch({
      type: CERRAR_EDICION_ITEM,
    });
  };

  // Abrir agregar item
  const abrirAgregarItem = () => {
    dispatch({
      type: ABRIR_AGREGAR_ITEM,
    });
  };

  // Cerrar agregar item
  const cerrarAgregarItem = () => {
    dispatch({
      type: CERRAR_AGREGAR_ITEM,
    });
  };

  // Selecciona el item que el usuario dio click
  const itemActual = (itemId) => {
    dispatch({
      type: ITEM_ACTUAL,
      payload: itemId,
    });
  };

  // Abrir el modal de agregar items
  const openModalAgregarItems = () => {
    dispatch({
      type: OPEN_MODAL_AGREGAR_ITEM,
    });
  };

  // Cerrar el modal de agregar items
  const closeModalAgregarItems = () => {
    dispatch({
      type: CLOSE_MODAL_AGREGAR_ITEM,
    });
  };

  // Abrir el modal de editar items
  const openModalEditarItems = () => {
    dispatch({
      type: OPEN_MODAL_EDITAR_ITEM,
    });
  };

  // Cerrar el modal de editar items
  const closeModalEditarItems = () => {
    dispatch({
      type: CLOSE_MODAL_EDITAR_ITEM,
    });
  };

  return (
    <itemContext.Provider
      value={{
        items: state.items,
        itemactual: state.itemactual,
        formulario: state.formulario,
        errorformulario: state.errorformulario,
        agregaraitem: state.agregaraitem,
        editaritem: state.editaritem,
        ModalEditarItem: state.ModalEditarItem,
        ModalAgregarItem: state.ModalAgregarItem,
        actionitem: state.actionitem,
        loadstatus: state.loadstatus,

        cargarItems,
        mostrarFormulario,
        mostrarError,
        agregarItem,
        eliminarItem,
        editarItem,
        abrirEdicionItem,
        cerrarEdicionItem,
        abrirAgregarItem,
        cerrarAgregarItem,
        itemActual,
        openModalEditarItems,
        closeModalEditarItems,
        openModalAgregarItems,
        closeModalAgregarItems,
      }}
    >
      {props.children}
    </itemContext.Provider>
  );
};

export default ItemState;
