import React, { Fragment, useState, useContext } from "react";

// Importando el context de las dependencias
import eventoContext from "../../context/eventos/eventoContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const NuevoEvento = () => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state del formulario
  const eventosContext = useContext(eventoContext);
  const {
    formulario,
    errorformulario,

    mostrarFormulario,
    ocultarFormulario,
    agregarEvento,
    mostrarError,
  } = eventosContext;

  // State para evento
  const [evento, guardarEvento] = useState({
    nombre: "",
    descripcion: "",
  });

  // Extraer nombre de evento
  const { nombre, marca, descripcion } = evento;

  // Lee los contenidos del input
  const onChangeEvento = (e) => {
    guardarEvento({
      ...evento,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un evento
  const onSubmitEvento = (e) => {
    e.preventDefault();

    // Validar evento
    if (nombre === "") {
      mostrarError();
      return;
    }

    if (descripcion === "") {
      mostrarError();
      return;
    }

    // Agregar al state
    agregarEvento(evento, token);

    // Reiniciar el form
    guardarEvento({
      nombre: "",
      descripcion: "",
    });
  };

  return (
    <div className="formulario formulario_busqueda">
      {!formulario ? (
        <form className="formulario-nuevo-proyecto">
          <button
            type="button"
            className="btn btn-block btn-primario"
            onClick={() => mostrarFormulario()}
          >
            Agregar un nuevo Evento
          </button>
        </form>
      ) : null}

      {formulario ? (
        <form className="formulario-nuevo-proyecto" onSubmit={onSubmitEvento}>
          <input
            type="text"
            className="input-text"
            placeholder="Nombre del evento"
            name="nombre"
            value={nombre}
            onChange={onChangeEvento}
          />
          <br></br>
          <br></br>
          <textarea
            id="descripcion"
            className="input-text"
            placeholder="Descripción del evento"
            name="descripcion"
            value={descripcion}
            onChange={onChangeEvento}
          ></textarea>

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Agregar Evento"
          />

          <button
            type="button"
            className="btn btn-block btn-primario"
            onClick={() => ocultarFormulario()}
          >
            Cancelar
          </button>
          {errorformulario ? (
            <p className="mensaje error">Todos los campos son obligatorios</p>
          ) : null}
        </form>
      ) : null}
    </div>
  );
};

export default NuevoEvento;
