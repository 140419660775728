import React, { Fragment, useState, useContext } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import ItemContext from "../../context/items/itemContext";

const NuevoItem = ({ encuestaactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de encuestas context
  const itemsContext = useContext(ItemContext);
  const {
    errorformulario,

    mostrarError,
    agregarItem,
    closeModalAgregarItems,
  } = itemsContext;

  // State para encuesta
  const [item, guardarItem] = useState({
    label: "",
    peso: "",
    imagen: "",
    iditem: encuestaactual.id,
  });

  // Extraer nombre de encuesta
  const { label, peso, imagen } = item;

  // Lee los contenidos del input
  const onChangeItem = (e) => {
    if (e.target.name === "imagen") {
      guardarItem({
        ...item,
        [e.target.name]: e.target.files[0],
      });
    } else {
      guardarItem({
        ...item,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Cuando el usuario envia un encuesta
  const onSubmitItem = (e) => {
    e.preventDefault();

    // Validar encuesta
    if (label === "") {
      mostrarError();
      return;
    }

    if (imagen === "") {
      mostrarError();
      return;
    }

    if (peso === "") {
      mostrarError();
      return;
    }

    // Agregar al state
    agregarItem(item, token);

    // Reiniciar el form
    guardarItem({
      nombre: "",
      marca: "",
      descripcion: "",
    });

    /* closeModalAgregarItems(); */
  };

  return (
    <Fragment>
      <h4>Agregar items a la encuesta: {encuestaactual.title}</h4>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitItem}>
        <input
          type="text"
          className="input-text"
          placeholder="Label"
          name="label"
          value={label}
          onChange={onChangeItem}
        />
        <br></br>
        <br></br>
        <input
          name="imagen"
          type="file"
          src="img_submit.gif"
          alt="Submit"
          accept="image/png, image/jpeg"
          onChange={onChangeItem}
        ></input>

        <p>Seleccione el peso del item</p>
        <label htmlFor="activo">1</label>
        <input
          type="radio"
          id="one"
          name="peso"
          value="1"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">2</label>
        <input
          type="radio"
          id="two"
          name="peso"
          value="2"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">3</label>
        <input
          type="radio"
          id="three"
          name="peso"
          value="3"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">4</label>
        <input
          type="radio"
          id="four"
          name="peso"
          value="4"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">5</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="5"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">6</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="6"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">7</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="7"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">8</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="8"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">9</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="9"
          onChange={onChangeItem}
        />

        <label htmlFor="activo">10</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="10"
          onChange={onChangeItem}
        />

        <br></br>
        <br></br>
        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Agregar Nuevo Item"
        />

        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Cerrar Agregar Item"
          onClick={() => closeModalAgregarItems()}
        />
        {errorformulario ? (
          <p className="mensaje error">Todos los campos son obligatorios</p>
        ) : null}
      </form>
    </Fragment>
  );
};

export default NuevoItem;
