import {
  ESTADISTICA_ACTUAL,
  ESTADISTICA_TOTAL,
  OPEN_MODAL_ESTADISTICAS,
  CLOSE_MODAL_ESTADISTICAS,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case ESTADISTICA_ACTUAL:
      return {
        ...state,
        estadisticas: action.payload,
      };

    case ESTADISTICA_TOTAL:
      return {
        ...state,
        total: action.payload,
      };

    case OPEN_MODAL_ESTADISTICAS:
      return {
        ...state,
        ModalEstadisticas: true,
      };

    case CLOSE_MODAL_ESTADISTICAS:
      return {
        ...state,
        ModalEstadisticas: false,
        estadisticas: [{}],
        total: [{}],
      };

    default:
      return state;
  }
};
