import React, { useContext } from "react";

// Import Router
import { useParams, Link } from "react-router-dom";

// Import Images
import logo from "../../images/logo.png";

// Context Usuarios
import UsuarioContext from "../../context/usuarios/usuarioContext";

const Barra = (props) => {
  // Get params of the router
  const { eventoactual } = useParams();

  // Extraer estado del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { usuariolog, cerrarSesion } = usuariosContext;

  const callCerrarSesion = (e) => {
    e.preventDefault();
    cerrarSesion();
  };

  /*   const openAdminInscritos = (e) => {
    props.history.push("/inscritos");
  }; */

  return (
    <header className="app-header">
      <img src={logo} alt="" className="logo" />

      <nav className="nav-principal">
        {/* < Link to="/inscritos" >Administración de Inscritos</ Link>
                < Link to="/administrador" >Administración de Eventos</ Link> */}
        <p className="nombre-usuario">
          Hola <span>{usuariolog}</span>
        </p>
        {eventoactual ? (
          <Link to="/administrador">Administración de Eventos</Link>
        ) : null}
        <a onClick={(e) => callCerrarSesion(e)}>Cerrar Sesion</a>
      </nav>
    </header>
  );
};

export default Barra;
