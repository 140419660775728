import React, { useReducer } from "react";
import axios from "axios";
import qs from "qs"; // Se movió aquí para estar en la parte superior de las importaciones

import usuarioContext from "./usuarioContext";
import usuarioReducer from "./usuarioReducer";

import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  OBTENER_USUARIO,
  USER_LOG,
  CERRAR_SESION,
  OBTENER_TOKEN,
  USUARIO_AUTENTICADO,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
} from "../../types";

const UsuarioState = (props) => {
  // State inicial
  const initialState = {
    autenticado: false,
    errorformulario: false,
    cargando: true,
    loader: true,
  };

  // Crear dispatch y state
  const [state, dispatch] = useReducer(usuarioReducer, initialState);

  // Funcion que recibe y envia al reducer los datos del login
  const loginUsuario = (datausuario, datapassword) => {
    var data = qs.stringify({
      username: datausuario,
      password: datapassword,
    });

    console.log(
      "Data00",
      qs.stringify({
        username: datausuario,
        password: datapassword,
      })
    );

    var config = {
      method: "post",
      url: process.env.REACT_APP_REST_API_SOURCE + "authenticate",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    };

    console.log(config);
    console.log("Data01", data);

    axios(config)
      .then(function (response) {
        dispatch({
          type: LOGIN_EXITOSO,
          payload: JSON.stringify(response.data.token),
        });

        dispatch({
          type: USER_LOG,
          payload: datausuario,
        });
      })
      .catch(function (error) {
        const alerta = {
          msg: "Error en la utenticacion",
          categoria: "alerta-error",
        };

        console.log(alerta);

        dispatch({
          type: LOGIN_ERROR,
          payload: alerta,
        });

        console.log(error);
      });
  };

  // Retorna el usuario autenticado una vez se tenga el token
  const usuarioAutenticado = async () => {
    // Se consulta el local storage por el token
    const token = localStorage.getItem("token").slice(1, -1);

    var config = {
      method: "get",
      url: process.env.REACT_APP_REST_API_SOURCE + "account/profile",
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        dispatch({
          type: OBTENER_USUARIO,
          payload: response.data.item.name,
        });

        dispatch({
          type: OBTENER_TOKEN,
          payload: token,
        });
      })
      .catch(function (error) {
        dispatch({
          type: LOGIN_ERROR,
        });

        /* console.log(error.response); */
      });

    if (token) {
      // TODO: Función para enviar el token por headers
      dispatch({
        type: OBTENER_USUARIO,
      });
    }
  };

  // Revisar si el usuario esta autenticado una vez se tenga el token
  const revisarAutenticado = async () => {
    // Se consulta el local storage por el token
    const gettoken = localStorage.getItem("token");

    if (gettoken) {
      // TODO: Función para enviar el token por headers
      dispatch({
        type: USUARIO_AUTENTICADO,
      });
    }
  };

  // Funcion para abrir el loader
  const abrirLoader = () => {
    dispatch({
      type: LOADER_ACTIVE,
    });
  };

  // Funcion para cerrar el loader
  const cerrarLoader = () => {
    dispatch({
      type: LOADER_INACTIVE,
    });
  };

  // Funcion para cerra sesion
  const cerrarSesion = () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  return (
    <usuarioContext.Provider
      value={{
        autenticado: state.autenticado,
        mensaje: state.mensaje,
        errorenformulario: state.errorformulario,
        cargando: state.cargando,
        usuariolog: state.usuariodata,
        token: state.token,
        loader: state.loader,

        loginUsuario,
        usuarioAutenticado,
        revisarAutenticado,
        cerrarSesion,
        abrirLoader,
        cerrarLoader,
      }}
    >
      {props.children}
    </usuarioContext.Provider>
  );
};

export default UsuarioState;
