import React from "react";
import Barra from "../layout/Barra";
import FormInscritos from "./FormInscritos";
import ListadoInscritos from "./ListadoInscritos";

const Inscritos = () => {
  return (
    <div className="contenedor-app" id="contenedor-app">
      <div className="seccion-principal">
        <Barra></Barra>

        <FormInscritos></FormInscritos>

        <main>
          <div className="contenedor-tareas">
            <ListadoInscritos></ListadoInscritos>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Inscritos;
