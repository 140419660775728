import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// Importa el contexto directamente
import usuarioContext from "../../context/usuarios/usuarioContext";

const RutaPrivada = ({ component: Component, ...rest }) => {
  // Utiliza useNavigate en lugar de useHistory
  const navigate = useNavigate();

  // Utiliza el contexto directamente
  const { autenticado, cargando, revisarAutenticado, usuarioAutenticado } =
    useContext(usuarioContext);

  useEffect(() => {
    const fetchData = async () => {
      await revisarAutenticado();
      if (!autenticado) {
        navigate("/");
      } else {
        await usuarioAutenticado();
      }
    };

    fetchData();
  }, [autenticado, navigate]);

  return autenticado && !cargando ? <Component /> : <Navigate to="/" />;
};

export default RutaPrivada;
