import React, { useReducer } from "react";
import axios from "axios";
import qs from "qs";

import encuestaContext from "./encuestaContext";
import EncuestaReducer from "./encuestaReducer";

import {
  CARGAR_ENCUESTAS,
  FORMULARIO_ENCUESTAS,
  AGREGAR_ENCUESTA,
  ELIMINAR_ENCUESTA,
  STOP_RELOAD,
  VALIDAR_FORMULARIO,
  ABRIR_EDICION_ENCUESTA,
  CERRAR_EDICION_ENCUESTA,
  ABRIR_AGREGAR_ENCUESTA,
  CERRAR_AGREGAR_ENCUESTA,
  ENCUESTA_ACTUAL,
  EDITAR_ENCUESTA,
  OPEN_MODAL_EDITAR_ENCUESTA,
  CLOSE_MODAL_EDITAR_ENCUESTA,
  OPEN_MODAL_AGREGAR_ENCUESTA,
  CLOSE_MODAL_AGREGAR_ENCUESTA,
  OPEN_MODAL_ELIMINAR_ENCUESTA,
  CLOSE_MODAL_ELIMINAR_ENCUESTA,
} from "../../types/";

const EncuestaState = (props) => {
  const initialState = {
    encuestas: [{}],
    encuestaactual: [{}],
    formulario: false,
    errorformulario: false,
    agregaraencuesta: false,
    editarencuesta: false,
    ModalEditarEncuesta: false,
    ModalAgregarEncuesta: false,
    ModalEliminarEncuesta: false,
    actioneditencuesta: false,
    loadstatus: false,
  };

  // Crear disptach y state
  const [state, dispatch] = useReducer(EncuestaReducer, initialState);

  // Cargar los eventos
  const cargarEncuestas = (passtoken, eventoactual) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "poll/?promoterId=" +
        eventoactual +
        "&limit=40&order=desc",
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        dispatch({
          type: CARGAR_ENCUESTAS,
          payload: response.data.items,
          status: response.data.status,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Agregar nuevo encuesta
  const agregarEncuesta = (encuesta, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var data = qs.stringify({
      title: encuesta.titulo,
      description: encuesta.descripcion,
      startsAt: "",
      endsAt: "",
      promoterId: encuesta.idevento,
      status: encuesta.status,
      published: encuesta.published,
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_REST_API_SOURCE + "poll/create/",
      headers: {
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
      })
      .catch(function (error) {
        /* console.log(error.response); */
      });

    // Enviar la data del nuevo encuesta Resetea el formulario
    dispatch({
      type: AGREGAR_ENCUESTA,
    });

    setTimeout(() => {
      dispatch({
        type: CLOSE_MODAL_AGREGAR_ENCUESTA,
      });
    }, 1000);
  };

  // Elimina un encuesta
  const eliminarEncuesta = (encuestaId, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var data = qs.stringify({});

    var config = {
      method: "delete",
      url: process.env.REACT_APP_REST_API_SOURCE + "poll/delete/" + encuestaId,
      headers: {
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
      })
      .catch(function (error) {
        /* console.log(error); */
      });

    dispatch({
      type: ELIMINAR_ENCUESTA,
      payload: encuestaId,
    });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
      dispatch({
        type: CLOSE_MODAL_ELIMINAR_ENCUESTA,
      });
    }, 3000);
  };

  // Editar encuesta
  const editarEncuesta = (encuesta, passtoken, eventoId, encuestaId) => {
    // Se consulta por el token
    const token = passtoken;

    // Insertar el encuesta en el state
    var qs = require("qs");
    var data = JSON.stringify({
      title: encuesta.titulo,
      description: encuesta.descripcion,
      startsAt: "",
      endsAt: "",
      promoterId: eventoId,
      status: encuesta.estado,
      published: encuesta.publicada,
    });
    var config = {
      method: "put",
      url: process.env.REACT_APP_REST_API_SOURCE + "poll/update/" + encuestaId,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch({
          type: EDITAR_ENCUESTA,
        });
      })
      .catch(function (error) {
        console.log(error.response.data);
      });

    setTimeout(() => {
      dispatch({
        type: CLOSE_MODAL_EDITAR_ENCUESTA,
      });
    }, 1000);
  };

  //Serie de funciones para el CRUD
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_ENCUESTAS,
    });
  };

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });
  };

  // Abrir edicion encuesta
  const abrirEdicionEncuesta = () => {
    dispatch({
      type: ABRIR_EDICION_ENCUESTA,
    });
  };

  // Cerrar edicion encuesta
  const cerrarEdicionEncuesta = () => {
    dispatch({
      type: CERRAR_EDICION_ENCUESTA,
    });
  };

  // Abrir agregar encuesta
  const abrirAgregarEncuesta = () => {
    dispatch({
      type: ABRIR_AGREGAR_ENCUESTA,
    });
  };

  // Cerrar agregar encuesta
  const cerrarAgregarEncuesta = () => {
    dispatch({
      type: CERRAR_AGREGAR_ENCUESTA,
    });
  };

  // Selecciona el encuesta que el usuario dio click
  const encuestaActual = (encuestaId) => {
    dispatch({
      type: ENCUESTA_ACTUAL,
      payload: encuestaId,
    });
  };

  // Abrir el modal de editar ancuestas
  const openModalEditarEncuesta = () => {
    dispatch({
      type: OPEN_MODAL_EDITAR_ENCUESTA,
    });
  };

  // Cerrar el modal de editar ancuestas
  const closeModalEditarEncuesta = () => {
    dispatch({
      type: CLOSE_MODAL_EDITAR_ENCUESTA,
    });
  };

  // Abrir el modal de agregar ancuestas
  const openModalAgregarEncuesta = () => {
    dispatch({
      type: OPEN_MODAL_AGREGAR_ENCUESTA,
    });
  };

  // Cerrar el modal de agregar ancuestas
  const closeModalAgregarEncuesta = () => {
    dispatch({
      type: CLOSE_MODAL_AGREGAR_ENCUESTA,
    });
  };

  // Abrir el modal de eliminar ancuestas
  const openModalEliminarEncuesta = () => {
    dispatch({
      type: OPEN_MODAL_ELIMINAR_ENCUESTA,
    });
  };

  // Cerrar el modal de eliminar ancuestas
  const closeModalEliminarEncuesta = () => {
    dispatch({
      type: CLOSE_MODAL_ELIMINAR_ENCUESTA,
    });
  };

  return (
    <encuestaContext.Provider
      value={{
        encuestas: state.encuestas,
        encuestaactual: state.encuestaactual,
        formulario: state.formulario,
        errorformulario: state.errorformulario,
        agregaraencuesta: state.agregaraencuesta,
        editarencuesta: state.editarencuesta,
        ModalEditarEncuesta: state.ModalEditarEncuesta,
        ModalAgregarEncuesta: state.ModalAgregarEncuesta,
        ModalEliminarEncuesta: state.ModalEliminarEncuesta,
        actionencuesta: state.actionencuesta,
        loadstatus: state.loadstatus,

        cargarEncuestas,
        editarEncuesta,
        mostrarFormulario,
        mostrarError,
        agregarEncuesta,
        eliminarEncuesta,
        abrirEdicionEncuesta,
        cerrarEdicionEncuesta,
        abrirAgregarEncuesta,
        cerrarAgregarEncuesta,
        encuestaActual,
        openModalEditarEncuesta,
        closeModalEditarEncuesta,
        openModalAgregarEncuesta,
        closeModalAgregarEncuesta,
        openModalEliminarEncuesta,
        closeModalEliminarEncuesta,
      }}
    >
      {props.children}
    </encuestaContext.Provider>
  );
};

export default EncuestaState;
