import React, { Fragment, useContext } from "react";
import Modal from "react-modal";

import EditarEncuesta from "./EditarEncuesta";
import EliminarEncuesta from "./EliminarEncuesta";
import ListadoEncuesta from "./ListadoEncuestas";
import NuevaEncuesta from "./NuevaEncuesta";
import Sorteos from "../sorteos/Sorteos";
import Estadisticas from "../estadisticas/Estadisticas";

import AgregarItem from "../items/AgregarItem";
import EditarItem from "../items/EditarItem";
import NuevoItem from "../items/NuevoItem";

// Importando el context de las dependencias
import eventoContext from "../../context/eventos/eventoContext";
import encuestasContext from "../../context/encuestas/encuestaContext";
import ItemContext from "../../context/items/itemContext";
import sorteoContext from "../../context/sorteos/sorteosContext";
import estadisticasContext from "../../context/estadisticas/estadisticasContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
  },
};

const customStylesBig = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "95%",
    width: "100%",
  },
};

const Encuestas = ({ eventoactual, nombreeventoactual }) => {
  // Extraer eventos del state inicial
  const eventosContext = useContext(eventoContext);
  const { cerrarAdminEvento } = eventosContext;

  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const {
    encuestaactual,
    editarencuesta,
    ModalEditarEncuesta,
    ModalAgregarEncuesta,
    ModalEliminarEncuesta,

    closeModalEditarEncuesta,
    openModalAgregarEncuesta,
    closeModalAgregarEncuesta,
    closeModalEliminarEncuesta,
  } = encuestaContext;

  // Obtener el state de estadisticas context
  const estadisticaContext = useContext(estadisticasContext);
  const {
    ModalEstadisticas,

    closeModalEstadisticas,
  } = estadisticaContext;

  // Obtener el state de encuestas context
  const itemsContext = useContext(ItemContext);
  const {
    agregaraitem,
    editaritem,
    ModalAgregarItem,
    ModalEditarItem,

    closeModalEditarItems,
    closeModalAgregarItems,
  } = itemsContext;

  // Obtener el state de sorteos context
  const sorteosContext = useContext(sorteoContext);
  const { sorteo } = sorteosContext;

  const FCloseModalEditarEncuesta = () => {
    closeModalEditarEncuesta();
  };

  const FCloseModalEliminarEncuesta = () => {
    closeModalEliminarEncuesta();
  };

  const FCloseModalEditarItem = () => {
    closeModalEditarItems();
  };

  const FCloseModalAgregarEncuesta = () => {
    closeModalAgregarEncuesta();
  };

  const FCloseModalAgregarItem = () => {
    closeModalAgregarItems();
  };

  const FCloseModalEstadisticas = () => {
    closeModalEstadisticas();
  };

  return (
    <div className="content-forms">
      {!agregaraitem && !sorteo && !agregaraitem ? (
        <Fragment>
          <h4>Encuestas para este evento</h4>
          <ListadoEncuesta eventoactual={eventoactual}></ListadoEncuesta>
          <button
            type="button"
            className="btn btn-block btn-primario"
            onClick={() => openModalAgregarEncuesta()}
          >
            Nueva Encuesta
          </button>
          <button
            type="button"
            className="btn btn-block btn-primario"
            onClick={() => cerrarAdminEvento()}
          >
            Volver al listado de Eventos
          </button>
          <br></br>
          <br></br>
        </Fragment>
      ) : null}

      {sorteo ? <Sorteos eventoactual={eventoactual} /> : null}

      {agregaraitem ? <AgregarItem /> : null}

      <Modal
        isOpen={ModalAgregarEncuesta}
        onRequestClose={FCloseModalAgregarEncuesta}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Agregar Encuesta</h2>
        <NuevaEncuesta
          eventoactual={eventoactual}
          nombreeventoactual={nombreeventoactual}
        ></NuevaEncuesta>
      </Modal>

      <Modal
        isOpen={ModalEditarEncuesta}
        onRequestClose={FCloseModalEditarEncuesta}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Editar Encuesta</h2>
        {editarencuesta ? <EditarEncuesta eventoactual={eventoactual} /> : null}
      </Modal>

      <Modal
        isOpen={ModalEliminarEncuesta}
        onRequestClose={FCloseModalEliminarEncuesta}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Eliminar Encuesta</h2>
        <EliminarEncuesta eventoactual={eventoactual} />
      </Modal>

      <Modal
        isOpen={ModalAgregarItem}
        onRequestClose={FCloseModalAgregarItem}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Agregar Item</h2>
        <NuevoItem encuestaactual={encuestaactual}></NuevoItem>
      </Modal>

      <Modal
        isOpen={ModalEditarItem}
        onRequestClose={FCloseModalEditarItem}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Editar Item</h2>
        {editaritem ? <EditarItem /> : null}
      </Modal>

      <Modal
        isOpen={ModalEstadisticas}
        onRequestClose={FCloseModalEstadisticas}
        style={customStylesBig}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <Estadisticas encuestaactual={encuestaactual}></Estadisticas>
      </Modal>
    </div>
  );
};

export default Encuestas;
