import React, { useEffect, useContext } from "react";
import Barra from "../layout/Barra";
import Loader from "../layout/Loader";
import ListadoEventos from "./ListadoEventos";
import NuevoEvento from "./NuevoEvento";

// Context Usuarios
import UsuarioContext from "../../context/usuarios/usuarioContext";

// Context Alerta
import AlertaContext from "../../context/alertas/alertaContext";

// Context Eventos
import eventosContext from "../../context/eventos/eventoContext";

const Eventos = (props) => {
  // Extraer estado del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { loader, cerrarLoader } = usuariosContext;

  // Extraer la funcion que muestra la alerta desde el context
  const alertaContext = useContext(AlertaContext);

  // Extraer el context de eventos
  const eventoContext = useContext(eventosContext);
  const { estadocarga } = eventoContext;

  // En caso que el usuario se haya loggeado o no haya logrado loggearse
  useEffect(() => {
    if (estadocarga) {
      cerrarLoader();
    }
  }, [estadocarga]);

  return (
    <div className="contenedor-app" id="contenedor-app">
      {loader ? <Loader></Loader> : null}

      <div className="seccion-principal">
        <Barra></Barra>

        <NuevoEvento />

        <main>
          <div className="contenedor-tareas">
            <ListadoEventos></ListadoEventos>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Eventos;
