import React, { Fragment, useContext, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Ganadores from "./Ganadores";
import Total from "./Total";
import Loader from "../layout/Loader";

// Importando el context de las dependencias
import sorteoContext from "../../context/sorteos/sorteosContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";
import encuestasContext from "../../context/encuestas/encuestaContext";
import itemsContext from "../../context/items/itemContext";

const Sorteos = ({ eventoactual }) => {
  // Obtener el state de sorteos context
  const sorteosContext = useContext(sorteoContext);
  const {
    errorformulario,
    listaganadores,
    generadosorteo,
    sorteos,
    total,
    loader,

    cerrarSorteo,
    generarSorteo,
    mostrarError,
    abrirGanadores,
    cargarGanadores,
    limpiarGanadores,
  } = sorteosContext;

  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const { encuestaactual } = encuestaContext;

  // State para el sorteo
  const [sorteo, guardarSorteo] = useState({
    ganadores: "1",
    idevento: eventoactual,
    idencuesta: encuestaactual.id,
    iditem: "",
  });

  // Extraer item del state inicial
  const itemContext = useContext(itemsContext);
  const {
    items,
    actionsorteo,

    cargarItems,
  } = itemContext;

  // Si el usuario se autentico y se genero el token se pregunta por los encuesta creados
  useEffect(() => {
    if (token) {
      cargarItems(token, encuestaactual);
      /*cargarGanadores(eventoactual, encuestaactual.id, token); */
    }
  }, [sorteo, generadosorteo, total, token]);

  // Extraer datos del sorteo
  const { ganadores, idevento, idencuesta, iditem } = sorteo;

  // Lee los contenidos del input
  const onChangeSorteo = (e) => {
    guardarSorteo({
      ...sorteo,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un encuesta
  const onSubmitSorteo = (e) => {
    e.preventDefault();

    // Limpiar ganadores
    limpiarGanadores();

    // Validar sorteo
    if (ganadores === "" && ganadores === "0") {
      mostrarError();
      return;
    }

    if (iditem === "") {
      mostrarError();
      return;
    }

    // Generar sorteo
    generarSorteo(ganadores, idevento, idencuesta, iditem, token);

    // Abrir gandores
    abrirGanadores();
  };

  return (
    <Fragment>
      <Tabs>
        <TabList>
          <Tab>Sorteo Ajuste</Tab>
          {/* {total.results === 0 ? (
            <Fragment></Fragment>
          ) : total.results > 0 ? (
            <Tab onClick={(e) => limpiarGanadores()}>Total de Ganadores</Tab>
          ) : (
            <Tab></Tab>
          )} */}
          <Tab onClick={(e) => limpiarGanadores()}>Total de Ganadores</Tab>
        </TabList>

        <TabPanel>
          <h2>Sorteo</h2>
          <h4>Sorteo para la encuesta: {encuestaactual.title}</h4>
          <form className="formulario-nuevo-proyecto" onSubmit={onSubmitSorteo}>
            <select
              name="iditem"
              defaultValue={iditem}
              onChange={onChangeSorteo}
              id="carsbrand"
              form="carform"
              className="input-text"
            >
              <option value="">Selecciona un item</option>
              {items.map((item, keyindex) => (
                <option value={item.id} key={keyindex}>
                  {item.label}
                </option>
              ))}
            </select>
            <input
              type="submit"
              className="btn btn-primario btn-block"
              value="Realizar Sorteo"
            />

            <input
              type="submit"
              className="btn btn-primario btn-block"
              value="Volver al listado de items"
              onClick={() => cerrarSorteo()}
            />
            {errorformulario ? (
              <p className="mensaje error">Todos los campos son obligatorios</p>
            ) : null}

            {generadosorteo ? (
              <p className="mensaje error">
                El sorteo no fue generado intente nuevamente
              </p>
            ) : null}

            {loader ? <Loader /> : null}

            <br></br>
            <br></br>
          </form>
          {listaganadores && sorteos.status && !actionsorteo ? (
            <Ganadores idevento={eventoactual} idencuesta={encuestaactual.id} />
          ) : null}
        </TabPanel>
        <TabPanel>
          <h2>Total de Ganadores</h2>
          <h4>Para la encuesta: {encuestaactual.title}</h4>
          <Total idevento={eventoactual} idencuesta={encuestaactual.id} />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default Sorteos;
