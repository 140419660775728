import React, { useContext } from "react";

// Importando el context de las dependencias
import itemsContext from "../../context/items/itemContext";

const Item = ({ item, onClickEliminar }) => {
  // Extraer item del state inicial
  const itemContext = useContext(itemsContext);
  const {
    itemActual,

    abrirEdicionItem,
    openModalEditarItems,
  } = itemContext;

  // Funcion para abrir editar encuesta
  const llamarEditarItem = () => {
    abrirEdicionItem();
    itemActual(item);
    openModalEditarItems();
  };

  return (
    <li key={item.keyIndex} className="tarea sombra" id={item.id}>
      <p>
        {item.label} <strong> | 'Peso' | {item.weight}</strong>
      </p>

      <div className="acciones">
        <button
          type="button"
          className="btn btn-primario"
          onClick={() => llamarEditarItem()}
        >
          Editar
        </button>

        <button
          type="button"
          className="btn btn-terciario"
          onClick={() => onClickEliminar(item.id)}
        >
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default Item;
