import {
  CARGAR_ITEMS,
  FORMULARIO_ITEMS,
  AGREGAR_ITEM,
  EDITAR_ITEM,
  ELIMINAR_ITEM,
  STOP_RELOAD,
  VALIDAR_FORMULARIO,
  ABRIR_EDICION_ITEM,
  CERRAR_EDICION_ITEM,
  ABRIR_AGREGAR_ITEM,
  CERRAR_AGREGAR_ITEM,
  ITEM_ACTUAL,
  OPEN_MODAL_EDITAR_ITEM,
  CLOSE_MODAL_EDITAR_ITEM,
  OPEN_MODAL_AGREGAR_ITEM,
  CLOSE_MODAL_AGREGAR_ITEM,
} from "../../types/";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_ITEMS:
      return {
        ...state,
        formulario: true,
      };

    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };

    case CARGAR_ITEMS:
      return {
        ...state,
        items: action.payload,
        loadstatus: action.status,
      };

    case AGREGAR_ITEM:
      return {
        ...state,
        formulario: false,
        errorformulario: false,
        actionitem: true,
      };

    case EDITAR_ITEM:
      return {
        ...state,
        actionitem: true,
      };

    case ELIMINAR_ITEM:
      return {
        ...state,
        actionitem: true,
      };

    case ABRIR_EDICION_ITEM:
      return {
        ...state,
        editaritem: true,
      };

    case CERRAR_EDICION_ITEM:
      return {
        ...state,
        editaritem: false,
      };

    case ABRIR_AGREGAR_ITEM:
      return {
        ...state,
        agregaraitem: true,
      };

    case CERRAR_AGREGAR_ITEM:
      return {
        ...state,
        agregaraitem: false,
      };

    case ITEM_ACTUAL:
      return {
        ...state,
        itemactual: action.payload,
      };

    case OPEN_MODAL_AGREGAR_ITEM:
      return {
        ...state,
        ModalAgregarItem: true,
      };

    case CLOSE_MODAL_AGREGAR_ITEM:
      return {
        ...state,
        ModalAgregarItem: false,
        actionitem: false,
      };

    case OPEN_MODAL_EDITAR_ITEM:
      return {
        ...state,
        ModalEditarItem: true,
      };

    case CLOSE_MODAL_EDITAR_ITEM:
      return {
        ...state,
        ModalEditarItem: false,
        actionitem: false,
      };

    case STOP_RELOAD:
      return {
        ...state,
        actionitem: false,
      };

    default:
      return state;
  }
};
