import React, { Fragment, useEffect, useContext, useState } from "react";
import Modal from "react-modal";

import Inscrito from "./Inscrito";

// Importando componentes de eliminar y editar
import EliminarInscrito from "./EliminarInscrito";
import EditarInscrito from "./EditarInscrito";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import InscritosContext from "../../context/inscritos/inscritosContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
  },
};

const customStylesEdicion = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
  },
};

const ListadoInscritos = () => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer data de los inscritos del state inicial
  const inscritosContext = useContext(InscritosContext);
  const {
    inscritos,
    actioninscritos,
    lastkey,
    getModalEdicionGuardada,

    cargarInscritos,
    closeModalEdicionGuardada,
  } = inscritosContext;

  // Si el usuario se autentico y se genero el token se pregunta por los eventos creados
  useEffect(() => {
    if (token) {
      cargarInscritos(token);
    }
  }, [token, actioninscritos]);

  // Variable de inscritos
  const listadoinscritos = inscritos;

  // Funcion que carga mas inscritos
  const cargarMasInscritos = (lastKey) => {
    cargarInscritos(token, lastKey);
  };

  // useState del popup
  const [getModalEditar, setModalEditar] = useState(false);
  const [getModalEliminar, setModalEliminar] = useState(false);
  const [getUserLoaded, setUserLoaded] = useState({
    iduser: "",
    username: "",
    surname: "",
  });

  // Funcion cerrar editar inscrito
  const FOpenModalEditarInscrito = (inscrito) => {
    setModalEditar(true);
    setUserLoaded(inscrito);
  };

  const FCloseModalEditarInscrito = () => {
    setModalEditar(false);
  };

  // Funcion cerrar eliminar inscrito
  const FOpenModalEliminarInscrito = (inscrito) => {
    setModalEliminar(true);
    setUserLoaded({
      iduser: inscrito.id,
      name: inscrito.name,
      surname: inscrito.surname,
    });
  };

  const FCloseModalEliminarInscrito = () => {
    setModalEliminar(false);
  };

  return (
    <Fragment>
      <h1>Desde listado inscritos</h1>
      <ul className="listado-tareas">
        <li className="usuario">
          <p>
            <strong>Nombre </strong>
          </p>
          <p>
            <strong>Telefóno</strong>
          </p>
          <p>
            <strong>Documento</strong>
          </p>
          <p>
            <strong>Correo</strong>
          </p>
          <p className="buttons">
            <strong>Acciones</strong>
          </p>
        </li>
        {actioninscritos ? (
          listadoinscritos.lenght === 0 ? (
            <li className="tarea">
              <p>No hay inscritos</p>
            </li>
          ) : (
            listadoinscritos.map((inscrito, keyIndex) => (
              <Inscrito
                inscrito={inscrito}
                number={keyIndex}
                key={keyIndex}
                FOpenModalEditarInscrito={FOpenModalEditarInscrito}
                FOpenModalEliminarInscrito={FOpenModalEliminarInscrito}
              ></Inscrito>
            ))
          )
        ) : null}
      </ul>
      {actioninscritos ? (
        listadoinscritos.lenght !== 0 ? (
          lastkey !== "" ? (
            <button
              type="button"
              className="btn btn-block btn-primario"
              onClick={() => cargarMasInscritos(lastkey)}
            >
              Cargar Más
            </button>
          ) : (
            <h5 className="center">No hay mas items para cargar</h5>
          )
        ) : null
      ) : null}

      <Modal
        isOpen={getModalEditar}
        onRequestClose={FCloseModalEditarInscrito}
        style={customStylesEdicion}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Editar Inscrito</h2>
        <EditarInscrito
          FCloseModalEditarInscrito={FCloseModalEditarInscrito}
          inscritoactual={getUserLoaded}
        />
      </Modal>

      <Modal
        isOpen={getModalEliminar}
        onRequestClose={FCloseModalEliminarInscrito}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Eliminar Inscrito</h2>
        <EliminarInscrito
          FCloseModalEliminarInscrito={FCloseModalEliminarInscrito}
          inscritoactual={getUserLoaded}
        />
      </Modal>

      <Modal
        isOpen={getModalEdicionGuardada}
        onRequestClose={closeModalEdicionGuardada}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2>Sus datos han sido guardados satisfactoriamente.</h2>

        <bottom
          className="btn btn-primario btn-submit btn-block"
          onClick={() => closeModalEdicionGuardada()}
        >
          Cerrar
        </bottom>
      </Modal>
    </Fragment>
  );
};

export default ListadoInscritos;
