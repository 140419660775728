import React from "react";

import {
  CARGAR_ENCUESTAS,
  FORMULARIO_ENCUESTAS,
  AGREGAR_ENCUESTA,
  ELIMINAR_ENCUESTA,
  STOP_RELOAD,
  VALIDAR_FORMULARIO,
  ABRIR_EDICION_ENCUESTA,
  CERRAR_EDICION_ENCUESTA,
  ABRIR_AGREGAR_ENCUESTA,
  CERRAR_AGREGAR_ENCUESTA,
  ENCUESTA_ACTUAL,
  EDITAR_ENCUESTA,
  OPEN_MODAL_EDITAR_ENCUESTA,
  CLOSE_MODAL_EDITAR_ENCUESTA,
  OPEN_MODAL_AGREGAR_ENCUESTA,
  CLOSE_MODAL_AGREGAR_ENCUESTA,
  OPEN_MODAL_ELIMINAR_ENCUESTA,
  CLOSE_MODAL_ELIMINAR_ENCUESTA,
} from "../../types/";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_ENCUESTAS:
      return {
        ...state,
        formulario: true,
      };

    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };

    case CARGAR_ENCUESTAS:
      return {
        ...state,
        encuestas: action.payload,
        loadstatus: action.status,
      };

    case EDITAR_ENCUESTA:
      console.log("Reducer Editar Encuesta");
      return {
        ...state,
        actionencuesta: true,
      };

    case ELIMINAR_ENCUESTA:
      return {
        ...state,
        actionencuesta: true,
      };

    case AGREGAR_ENCUESTA:
      return {
        ...state,
        formulario: false,
        errorformulario: false,
        actionencuesta: true,
      };

    case ABRIR_EDICION_ENCUESTA:
      return {
        ...state,
        editarencuesta: true,
      };

    case CERRAR_EDICION_ENCUESTA:
      return {
        ...state,
        editarencuesta: false,
        actionencuesta: false,
      };

    case ABRIR_AGREGAR_ENCUESTA:
      return {
        ...state,
        agregaraencuesta: true,
      };

    case CERRAR_AGREGAR_ENCUESTA:
      return {
        ...state,
        agregaraencuesta: false,
      };

    case ENCUESTA_ACTUAL:
      return {
        ...state,
        encuestaactual: action.payload,
      };

    case OPEN_MODAL_EDITAR_ENCUESTA:
      return {
        ...state,
        ModalEditarEncuesta: true,
      };

    case CLOSE_MODAL_EDITAR_ENCUESTA:
      return {
        ...state,
        ModalEditarEncuesta: false,
        actionencuesta: false,
      };

    case OPEN_MODAL_AGREGAR_ENCUESTA:
      return {
        ...state,
        ModalAgregarEncuesta: true,
      };

    case CLOSE_MODAL_AGREGAR_ENCUESTA:
      return {
        ...state,
        ModalAgregarEncuesta: false,
        actionencuesta: false,
      };

    case OPEN_MODAL_ELIMINAR_ENCUESTA:
      return {
        ...state,
        ModalEliminarEncuesta: true,
      };

    case CLOSE_MODAL_ELIMINAR_ENCUESTA:
      return {
        ...state,
        ModalEliminarEncuesta: false,
        actionencuesta: false,
      };

    case STOP_RELOAD:
      return {
        ...state,
        actionitem: false,
      };

    default:
      return state;
  }
};
