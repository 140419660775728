import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import UsuarioContext from "../../context/usuarios/usuarioContext";
import AlertaContext from "../../context/alertas/alertaContext";

const Login = () => {
  const usuariosContext = useContext(UsuarioContext);
  const { mensaje, autenticado, errorenformulario, loginUsuario } =
    usuariosContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const navigate = useNavigate(); // Utiliza useNavigate para la navegación

  useEffect(() => {
    if (autenticado) {
      navigate("/administrador"); // Utiliza navigate en lugar de props.history.push
    } else {
      navigate("/"); // Utiliza navigate en lugar de props.history.push
    }
  }, [mensaje, autenticado, navigate]);

  const [usuario, guardarUsuario] = useState({
    username: "",
    password: "",
  });

  const { username, password } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (username.trim() === "" || password.trim() === "") {
      mostrarAlerta("Todos los campos son obligatorios", "alerta-error");
      return;
    }

    loginUsuario(usuario.username, usuario.password);
  };

  return (
    <div className="form-usuario">
      {alerta ? (
        <div className={`alerta ${alerta.categoria}`}> {alerta.msg} </div>
      ) : null}
      <div className="contenedor-form sombra-dark">
        <h1>Iniciar Sesión</h1>

        <form onSubmit={onSubmit}>
          <div className="campo-form">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Tu Email"
              onChange={onChange}
            />
          </div>

          <div className="campo-form">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Tu password"
              onChange={onChange}
            />
          </div>

          <div className="campo-form">
            <input
              type="submit"
              className="btn btn-primario btn-block"
              value="Iniciar Sesión"
            />
          </div>

          {errorenformulario ? (
            <p>
              El Usuario o el password estan mal ingresados por favor revisa.
            </p>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default Login;
