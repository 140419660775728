import React, { Fragment, useContext } from "react";
import ListadoItems from "./ListadoItems";

// Importando el context de las dependencias
import encuestasContext from "../../context/encuestas/encuestaContext";
import ItemContext from "../../context/items/itemContext";

const AgregarEncuesta = () => {
  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const { encuestaactual } = encuestaContext;

  // Obtener el state de encuestas context
  const itemsContext = useContext(ItemContext);
  const { cerrarAgregarItem, openModalAgregarItems } = itemsContext;

  return (
    <Fragment>
      <ListadoItems encuestaactual={encuestaactual}></ListadoItems>
      <button
        type="button"
        className="btn btn-block btn-primario"
        onClick={() => openModalAgregarItems()}
      >
        Nuevo Item
      </button>
      <button
        type="button"
        className="btn btn-block btn-primario"
        onClick={() => cerrarAgregarItem()}
      >
        Volver al listado de encuestas
      </button>
      <br></br>
      <br></br>
    </Fragment>
  );
};

export default AgregarEncuesta;
