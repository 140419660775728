import React, { Fragment, useContext, useState } from "react";

// Importando el context de las dependencias
import estadisticasContext from "../../context/estadisticas/estadisticasContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

// Canvas JS CHART
import CanvasJSReact from "@canvasjs/react-charts";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Estadisticas = ({ encuestaactual }) => {
  // Obtener el state de estadisticas context
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const estadisticaContext = useContext(estadisticasContext);
  const {
    total,
    estadisticas,

    estadisticaActual,
    totalEstadistica,
  } = estadisticaContext;

  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Datos Prueba
  /* const pruebaEstadisticas = [
    { total: 4181563, label: "PlatotalStation 3" },
    { total: 2175498, label: "Wii" },
    { total: 3125844, label: "Xbox 360" },
    { total: 1176121, label: "Nintendo DS" },
    { total: 1727161, label: "PSP" },
    { total: 4303364, label: "Nintendo 3DS" },
    { total: 1717786, label: "PS Vita" },
    { total: 4181563, label: "PlatotalStation 3" },
    { total: 2175498, label: "Wii" },
    { total: 3125844, label: "Xbox 360" },
    { total: 1176121, label: "Nintendo DS" },
    { total: 1727161, label: "PSP" },
    { total: 4303364, label: "Nintendo 3DS" },
    { total: 1717786, label: "PS Vita" },
    { total: 4181563, label: "PlatotalStation 3" },
    { total: 2175498, label: "Wii" },
    { total: 3125844, label: "Xbox 360" },
    { total: 1176121, label: "Nintendo DS" },
    { total: 1727161, label: "PSP" },
    { total: 4303364, label: "Nintendo 3DS" },
    { total: 1717786, label: "PS Vita" },
    { total: 4181563, label: "PlatotalStation 3" },
    { total: 2175498, label: "Wii" },
    { total: 3125844, label: "Xbox 360" },
    { total: 1176121, label: "Nintendo DS" },
    { total: 1727161, label: "PSP" },
    { total: 4303364, label: "Nintendo 3DS" },
    { total: 1717786, label: "PS Vita" },
  ];

  const pruebaTotal = 18407337 * 4; */

  // State de la grafica

  const [getStatusGraph, setStatusGraph] = useState(true);

  const datosestadisticas = estadisticas;

  // Se crea el objeto para la grafica
  const stringDataEstadisticas = datosestadisticas.map((element) => ({
    y: (element.total / total.count) * 100,
    label: element.label + " = " + element.total + "V",
  }));

  // Se reduce el objeto por el numero de votos dejando primero el ganador por número de votos

  const sortNumberOfVotes = datosestadisticas.sort(function (x, y) {
    return x.total - y.total;
  });

  /* console.table('Tabla',sortNumberOfVotes); */

  const optionsNew = {
    title: {
      text: "Basic Column Chart in React",
    },
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1", // "light1", "dark1", "dark2",
    data: [
      {
        type: "doughnut",
        indexLabel: "{label} - {y}%",
        showInLegend: false,
        startAngle: -90,
        dataPoints: [
          { label: "Apple", y: 10 },
          { label: "Orange", y: 15 },
          { label: "Banana", y: 25 },
          { label: "Mango", y: 30 },
          { label: "Grape", y: 28 },
        ],
        indexLabelFontSize: 12,
      },
    ],
  };

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1", // "light1", "dark1", "dark2",
    data: [
      {
        type: "doughnut",
        indexLabel: "{label} - {y}%",
        showInLegend: false,
        startAngle: -90,
        dataPoints: stringDataEstadisticas,
        indexLabelFontSize: 12,
      },
    ],
  };

  const optionsDos = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1", // "light1", "dark1", "dark2",
    data: [
      {
        type: "pyramid",
        indexLabel: "{label} - {y}%",
        showInLegend: false,
        startAngle: -90,
        dataPoints: stringDataEstadisticas,
        indexLabelFontSize: 12,
      },
    ],
  };

  const optionsTres = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1", // "light1", "dark1", "dark2",
    data: [
      {
        type: "pie",
        indexLabel: "{label} - {y}%",
        showInLegend: false,
        legendMarkerType: "circle",
        dataPoints: stringDataEstadisticas,
        indexLabelFontSize: 12,
      },
    ],
  };

  const optionsCuatro = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light1", // "light1", "dark1", "dark2",
    data: [
      {
        type: "funnel",
        indexLabelPlacement: "inside",
        indexLabelFontColor: "white",
        showInLegend: false,
        indexLabel: "{label} - {y}%",
        startAngle: -90,
        dataPoints: stringDataEstadisticas,
        indexLabelFontSize: 12,
      },
    ],
  };

  const reloadGraph = () => {
    setStatusGraph(false);
    estadisticaActual(encuestaactual.id, token);
    totalEstadistica(encuestaactual.id, token);

    setTimeout(() => {
      setStatusGraph(true);
    }, 100);
  };

  return (
    <Fragment>
      <Tabs>
        <TabList>
          <Tab>Total</Tab>
          {total.status ? (
            total.count > 0 ? (
              <Tab>Gráfica</Tab>
            ) : (
              <Tab></Tab>
            )
          ) : (
            <Tab></Tab>
          )}
          {total.status ? (
            total.count > 0 ? (
              <Tab>Tabla</Tab>
            ) : (
              <Tab></Tab>
            )
          ) : (
            <Tab></Tab>
          )}
        </TabList>

        <TabPanel>
          <h2>Total de votos en esta encuesta</h2>
          <h4>Resultado para la encuesta: </h4>

          {total.status ? <h1>{total.count}</h1> : null}
        </TabPanel>

        <TabPanel>
          <h2>Total de votos por item</h2>
          <h4>Sorteo para la encuesta: </h4>
          {getStatusGraph ? (
            <CanvasJSChart
              options={options}
              /* onRef={ref => this.chart = ref} */
            />
          ) : (
            <h2>Recargando ...</h2>
          )}

          <button
            type="button"
            className="btn btn-primario btn-block"
            onClick={() => reloadGraph()}
          >
            Recargar la gráfica
          </button>
        </TabPanel>

        <TabPanel>
          <h2>Total de votos por item</h2>
          <h4>Sorteo para la encuesta: </h4>
          {getStatusGraph ? (
            <div className="contentTableWinner">
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                className="tableWinners"
              >
                <thead>
                  <tr>
                    <td>Participante</td>
                    <td>Votos por participante</td>
                    <td>Procentaje de la votación</td>
                  </tr>
                </thead>
                <tbody>
                  {sortNumberOfVotes.reverse().map((item, keyTable) => (
                    <tr key={keyTable}>
                      <td>{item.label}</td>
                      <td>{item.total}</td>
                      <td>{(item.total / total.count) * 100}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total encuesta:</td>
                    <td colSpan="2">{total.count} / votos</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : (
            <h2>Recargando ...</h2>
          )}

          <button
            type="button"
            className="btn btn-primario btn-block"
            onClick={() => reloadGraph()}
          >
            Recargar la gráfica
          </button>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default Estadisticas;
