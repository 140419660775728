import React, { useReducer } from "react";
import axios from "axios";
import qs from "qs";

import eventoContext from "./eventoContext";
import eventoReducer from "./eventoReducer";
import {
  FORMULARIO_EVENTO,
  AGREGAR_EVENTO,
  VALIDAR_FORMULARIO,
  EVENTO_ACTUAL,
  ELIMINAR_EVENTO,
  STOP_RELOAD,
  CARGAR_EVENTOS,
  ABRIR_EDICION_EVENTO,
  CERRAR_EDICION_EVENTO,
  ABRIR_ADMINISTRACION_EVENTO,
  CERRAR_ADMINISTRACION_EVENTO,
  EDITAR_EVENTO,
  EVENTOS_MARCA,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
} from "../../types";

const EventoState = (props) => {
  const initialState = {
    eventos: [{}],
    eventosmarca: [{}],
    eventoactual: "",
    formulario: false,
    estadocarga: false,
    errorformulario: false,
    editarevento: false,
    administrarevento: false,
    mensajeError: "",
    evento: null,
    actionevento: false,
    loadstatus: false,
  };

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(eventoReducer, initialState);

  // Agregar nuevo evento
  const agregarEvento = (evento, passtoken) => {
    console.log(evento);

    // Se consulta por el token
    const token = passtoken;

    // Insertar el evento en el state
    var data = qs.stringify({
      name: evento.nombre,
      description: evento.descripcion,
      brand: "unicef",
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_REST_API_SOURCE + "promoter/create/",
      headers: {
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // Enviar la data del nuevo evento Resetea el formulario
        dispatch({
          type: AGREGAR_EVENTO,
          dispatch: response.data,
        });
      })
      .catch(function (error) {
        console.log(error.request.status);
      });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
    }, 1000);
  };

  // Editar evento
  const editarEvento = (evento, passtoken, eventoId) => {
    // Se consulta por el token
    const token = passtoken;

    // Insertar el evento en el state
    var data = JSON.stringify({
      name: evento.nombre,
      description: evento.descripcion,
      brand: evento.marca,
    });
    var config = {
      method: "put",
      url:
        process.env.REACT_APP_REST_API_SOURCE + "promoter/update/" + eventoId,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
      })
      .catch(function (error) {
        /* console.log(error.response); */
      });

    // Enviar la data del nuevo evento Resetea el formulario
    dispatch({
      type: EDITAR_EVENTO,
    });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
    }, 1000);
  };

  // Elimina un evento
  const eliminarEvento = (eventoId, passtoken) => {
    // Se consulta por el token
    const token = passtoken;

    var data = qs.stringify({});

    var config = {
      method: "delete",
      url:
        process.env.REACT_APP_REST_API_SOURCE + "promoter/delete/" + eventoId,
      headers: {
        Authorization: token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
      })
      .catch(function (error) {
        /* console.log(error); */
      });

    dispatch({
      type: ELIMINAR_EVENTO,
      payload: eventoId,
    });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
    }, 3000);
  };

  // Cargar los eventos
  const cargarEventos = (passtoken) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE + "promoter/?limit=40&order=desc",
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(response.data); */
        dispatch({
          type: CARGAR_EVENTOS,
          payload: response.data.items,
          status: response.data.status,
        });
      })
      .catch(function (error) {
        /* console.log(error.data); */
      });
  };

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });
  };

  // Selecciona el evento que el usuario dio click
  const eventoActual = (eventoId) => {
    dispatch({
      type: EVENTO_ACTUAL,
      payload: eventoId,
    });
  };

  //Serie de funciones para el CRUD
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_EVENTO,
    });
  };

  // Abrir edicion evento
  const abrirEdicionEvento = () => {
    // Enviar la data del nuevo evento Resetea el formulario
    dispatch({
      type: ABRIR_EDICION_EVENTO,
    });
  };

  // Cerrar edicion evento
  const cerrarEdicionEvento = () => {
    // Enviar la data del nuevo evento Resetea el formulario
    dispatch({
      type: CERRAR_EDICION_EVENTO,
    });
  };

  // Abrir admin evento
  const abrirAdminEvento = () => {
    // Enviar la data del nuevo evento Resetea el formulario
    dispatch({
      type: ABRIR_ADMINISTRACION_EVENTO,
    });
  };

  // Cerrar admin evento
  const cerrarAdminEvento = () => {
    // Enviar la data del nuevo evento Resetea el formulario
    dispatch({
      type: CERRAR_ADMINISTRACION_EVENTO,
    });
  };

  // Obtener los eventos de una marca
  const eventosMarca = (proyectoId) => {
    dispatch({
      type: EVENTOS_MARCA,
      payload: proyectoId,
    });
  };

  // Funcion para abrir el loader
  const abrirLoader = () => {
    dispatch({
      type: LOADER_ACTIVE,
    });
  };

  // Funcion para cerrar el loader
  const cerrarLoader = () => {
    dispatch({
      type: LOADER_INACTIVE,
    });
  };

  return (
    <eventoContext.Provider
      value={{
        eventos: state.eventos,
        eventosmarca: state.eventosmarca,
        formulario: state.formulario,
        errorformulario: state.errorformulario,
        evento: state.evento,
        merror: state.mensajeError,
        eventoactual: state.eventoactual,
        editarevento: state.editarevento,
        administrarevento: state.administrarevento,
        estadocarga: state.estadocarga,
        actionevento: state.actionevento,
        loadstatus: state.loadstatus,

        mostrarFormulario,
        agregarEvento,
        editarEvento,
        mostrarError,
        eventoActual,
        cargarEventos,
        eventosMarca,
        abrirEdicionEvento,
        cerrarEdicionEvento,
        abrirAdminEvento,
        cerrarAdminEvento,
        eliminarEvento,
        abrirLoader,
        cerrarLoader,
      }}
    >
      {props.children}
    </eventoContext.Provider>
  );
};

export default EventoState;
