import React, { Fragment, useContext, useEffect } from "react";
import Encuesta from "./Encuesta";
import Barloader from "../layout/Barloader";

// Importando el context de las dependencias
import encuestasContext from "../../context/encuestas/encuestaContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const ListadoEncuestas = ({ eventoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const {
    encuestas,
    actionencuesta,
    loadstatus,

    cargarEncuestas,
  } = encuestaContext;

  // Si el usuario se autentico y se genero el token se pregunta por los encuesta creados
  useEffect(() => {
    if (token) {
      cargarEncuestas(token, eventoactual);
    }

    if (actionencuesta) {
      cargarEncuestas(token, eventoactual);
    }
  }, [actionencuesta]);

  return (
    <Fragment>
      <ul className="listado-tareas">
        {encuestas.lenght === 0 ? (
          <li className="tarea">
            <p>No hay tareas</p>
          </li>
        ) : (
          encuestas.map((encuesta, keyIndex) =>
            loadstatus ? (
              <Encuesta
                encuesta={encuesta}
                number={keyIndex}
                key={keyIndex}
              ></Encuesta>
            ) : (
              <Barloader key={keyIndex}></Barloader>
            )
          )
        )}
      </ul>
    </Fragment>
  );
};

export default ListadoEncuestas;
