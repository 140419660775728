import React, { Fragment, useContext, useEffect } from "react";
import Item from "./Item";
import Barloader from "../layout/Barloader";

// Importando el context de las dependencias
import itemsContext from "../../context/items/itemContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const ListadoItems = ({ encuestaactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer item del state inicial
  const itemContext = useContext(itemsContext);
  const {
    items,
    actionitem,
    loadstatus,

    cargarItems,
    eliminarItem,
  } = itemContext;

  // Si el usuario se autentico y se genero el token se pregunta por los encuesta creados
  useEffect(() => {
    if (token) {
      cargarItems(token, encuestaactual);
    }

    if (actionitem) {
      cargarItems(token, encuestaactual);
    }
  }, [actionitem]);

  // Elimina un evento
  const onClickEliminar = (identificador) => {
    eliminarItem(identificador, token);
  };

  return (
    <Fragment>
      <h4>Items agregrados para la encuesta: {encuestaactual.title}</h4>
      <ul className="listado-tareas">
        {items.lenght === 0 ? (
          <li className="tarea">
            <p>No hay tareas</p>
          </li>
        ) : (
          items.map((item, keyIndex) =>
            loadstatus ? (
              <Item
                item={item}
                number={keyIndex}
                key={keyIndex}
                onClickEliminar={onClickEliminar}
              ></Item>
            ) : (
              <Barloader key={keyIndex}></Barloader>
            )
          )
        )}
      </ul>
    </Fragment>
  );
};

export default ListadoItems;
