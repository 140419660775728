import React, { Fragment, useState, useContext } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import EncuestaContext from "../../context/encuestas/encuestaContext";

const NuevaEncuesta = ({ eventoactual, nombreeventoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de encuestas context
  const encuestasContext = useContext(EncuestaContext);
  const {
    errorformulario,

    mostrarError,
    agregarEncuesta,
    closeModalAgregarEncuesta,
  } = encuestasContext;

  // State para encuesta
  const [encuesta, guardarEncuesta] = useState({
    titulo: "",
    status: "0",
    published: "0",
    descripcion: "",
    idevento: eventoactual,
  });

  // Extraer nombre de encuesta
  const { titulo, status, descripcion, published } = encuesta;

  // Lee los contenidos del input
  const onChangeEncuesta = (e) => {
    guardarEncuesta({
      ...encuesta,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un encuesta
  const onSubmitEncuesta = (e) => {
    e.preventDefault();

    // Validar encuesta
    if (titulo === "") {
      mostrarError();
      return;
    }

    if (status === "") {
      mostrarError();
      return;
    }

    if (published === "") {
      mostrarError();
      return;
    }

    if (descripcion === "") {
      mostrarError();
      return;
    }

    // Agregar al state
    agregarEncuesta(encuesta, token);

    // Reiniciar el form
    guardarEncuesta({
      titulo: "",
      status: "0",
      published: "0",
      descripcion: "",
    });

    /* closeModalAgregarEncuesta(); */
  };

  return (
    <Fragment>
      <h4>Agregar encuesta para el evento: {nombreeventoactual}</h4>
      {
        <form className="formulario-nuevo-proyecto" onSubmit={onSubmitEncuesta}>
          <input
            type="text"
            className="input-text"
            placeholder="Titulo"
            name="titulo"
            value={titulo}
            onChange={onChangeEncuesta}
          />
          <br></br>
          <br></br>
          <input
            type="radio"
            id="activo"
            name="status"
            value="1"
            onChange={onChangeEncuesta}
            checked={status === "1"}
          />
          <label htmlFor="activo">Activa</label>
          <input
            type="radio"
            id="inactivo"
            name="status"
            value="0"
            onChange={onChangeEncuesta}
            checked={status === "0"}
          />
          <label htmlFor="activo">Inactiva</label>
          <br></br>
          <br></br>
          <input
            type="radio"
            id="activo"
            name="published"
            value="1"
            onChange={onChangeEncuesta}
            checked={published === "1"}
          />
          <label htmlFor="activo">Publicada</label>
          <input
            type="radio"
            id="inactivo"
            name="publicada"
            value="0"
            onChange={onChangeEncuesta}
            checked={published === "0"}
          />
          <label htmlFor="activo">No Publicada</label>
          <br></br>
          <br></br>
          <textarea
            id="descripcion"
            className="input-text"
            placeholder="Descripción de la encuesta"
            name="descripcion"
            value={descripcion}
            onChange={onChangeEncuesta}
          ></textarea>

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Agregar Nueva Encuesta"
          />

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Cerrar Agregar"
            onClick={() => closeModalAgregarEncuesta()}
          />
          {errorformulario ? (
            <p className="mensaje error">Todos los campos son obligatorios</p>
          ) : null}
        </form>
      }
    </Fragment>
  );
};

export default NuevaEncuesta;
