import React from "react";

// Import Router
import { useParams } from "react-router-dom";

import Barra from "../layout/Barra";
import Sorteos from "../rifa/Sorteos";

const Rifas = () => {
  // Get params of the router
  const { eventoactual } = useParams();
  const { nombreactual } = useParams();

  return (
    <div className="contenedor-app" id="contenedor-app">
      <div className="seccion-principal">
        <Barra></Barra>

        <main>
          <div className="contenedor-tareas">
            <div className="content-forms">
              <Sorteos
                eventoactual={eventoactual}
                nombreactual={nombreactual}
              ></Sorteos>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Rifas;
