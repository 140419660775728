import React, { Fragment, useContext, useState } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import rifaContext from "../../context/rifa/rifaContext";

const Ganadores = ({ eventoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de rifas context
  const rifasContext = useContext(rifaContext);
  const {
    sorteos,
    enviadosganadores,

    agregarGanadores,
  } = rifasContext;

  // Extraer datos de los ganadores desde el state
  const listadogandores = sorteos.items;

  // Se crea el state para el formulario
  const [getGanadores, setGanadores] = useState({
    items: [
      {
        userId: "",
        promoterId: "",
        pollId: "",
        raffleType: "normal",
      },
    ],
  });

  // Se crea la funcion que toma el evento onchange del formulario
  const onChangeGandores = (e) => {
    const checked = e.target.checked;

    const arrayganadores = getGanadores.items;

    // Se eliminan los datos vacios

    const evaldeleteid = arrayganadores.filter((item) => item.userId !== "");

    setGanadores({
      items: evaldeleteid,
    });

    // Se evalua el estado de los checkbox

    if (checked) {
      const evaaddlid = arrayganadores.filter(
        (item) => item.userId == e.target.value
      );

      setGanadores({
        ...getGanadores,
        items: [
          ...getGanadores.items,
          {
            [e.target.name]: e.target.value,
            promoterId: eventoactual,
            pollId: "",
            raffleType: "final",
          },
        ],
      });
    } else {
      const evaldeleteid = arrayganadores.filter(
        (item) => item.userId !== e.target.value
      );

      setGanadores({
        items: evaldeleteid,
      });
    }
  };

  // Cuando el usuario envia los ganadores
  const onSubmitGanadores = (e) => {
    e.preventDefault();

    const arrayganadores = getGanadores.items;

    // Se eliminan los datos vacios

    const evaldeleteid = arrayganadores.filter((item) => item.userId !== "");

    setGanadores({
      items: evaldeleteid,
    });

    const ganadoresEnviar = {
      items: evaldeleteid,
    };

    // Agregar al state
    agregarGanadores(ganadoresEnviar, token);
  };

  return (
    <Fragment>
      <h4>Ganadores</h4>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitGanadores}>
        <ul className="listado-proyectos">
          {listadogandores.length != undefined ? (
            listadogandores.length > 0 ? (
              listadogandores.map((ganador, keyIndex) => (
                <li className="tarea sombra" key={keyIndex}>
                  <p>
                    <strong>Nombre: </strong>
                    {ganador.fullname}
                    <br></br> <strong>Telefóno:</strong> {ganador.cellphone}
                    <br></br> <strong>Id:</strong> {ganador.id}
                    <br></br> <strong>Documento:</strong> {ganador.documentType}
                    . {ganador.documentNumber}
                    <br></br> <strong>Correo:</strong> {ganador.email}
                  </p>
                </li>
              ))
            ) : (
              <li className="tarea sombra">
                <div className="acciones">
                  Aun no hay ganadores en esta encuesta
                </div>
              </li>
            )
          ) : null}
        </ul>

        {enviadosganadores ? (
          <p className="mensaje error">
            Los ganadores no fueron enviados intente nuevamente
          </p>
        ) : null}
      </form>
      <br></br>
    </Fragment>
  );
};

export default Ganadores;
