import React from "react";
import Encuestas from "../encuesta/Encuestas";

const AdministrarEvento = ({ eventoactual }) => {
  // Extraer datos del evento actual
  const { name, id } = eventoactual[0];

  return (
    <div className="listado-eventos">
      <div className="eventos">
        <h3>Administrar evento: {name}</h3>
        <Encuestas eventoactual={id} nombreeventoactual={name}></Encuestas>
      </div>
    </div>
  );
};

export default AdministrarEvento;
