import {
  ABRIR_SORTEO,
  CERRAR_SORTEO,
  AGREGAR_SORTEO,
  NOAGREGAR_SORTEO,
  VALIDAR_FORMULARIO,
  ABRIR_GANADORES,
  CERRAR_GANADORES,
  ELIMINAR_GANADORES,
  RECARGAR_GANADORES,
  CARGAR_GANADORES,
  ENVIADOS_GANADORES,
  NOENVIADOS_GANADORES,
  LIMPIAR_GANADORES,
  STOP_RELOAD,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
  OPEN_MODAL_REALIZAR_SORTEO,
  CLOSE_MODAL_REALIZAR_SORTEO,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case ABRIR_SORTEO:
      return {
        ...state,
        sorteo: true,
      };

    case CERRAR_SORTEO:
      return {
        ...state,
        sorteo: false,
        sorteos: [{}],
        enviadosganadores: false,
        generadosorteo: false,
        errorformulario: false,
      };

    case AGREGAR_SORTEO:
      return {
        ...state,
        errorformulario: false,
        sorteos: action.payload,
        generadosorteo: false,
      };

    case LIMPIAR_GANADORES:
      return {
        ...state,
        sorteos: [{}],
      };

    case NOAGREGAR_SORTEO:
      return {
        ...state,
        generadosorteo: true,
      };

    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };

    case ABRIR_GANADORES:
      return {
        ...state,
        listaganadores: true,
        actionsorteo: true,
      };

    case CERRAR_GANADORES:
      return {
        ...state,
        listaganadores: false,
      };

    case CARGAR_GANADORES:
      return {
        ...state,
        total: action.payload,
        actiontotal: true,
      };

    case ENVIADOS_GANADORES:
      return {
        ...state,
        enviadosganadores: false,
      };

    case NOENVIADOS_GANADORES:
      return {
        ...state,
        enviadosganadores: true,
      };

    case ELIMINAR_GANADORES:
      return {
        ...state,
        actiontotal: true,
      };

    case RECARGAR_GANADORES:
      return {
        ...state,
        actiontotal: false,
      };

    case STOP_RELOAD:
      return {
        actionsorteo: false,
      };

    case OPEN_MODAL_REALIZAR_SORTEO:
      return {
        ModalRealizarSorteo: true,
      };

    case CLOSE_MODAL_REALIZAR_SORTEO:
      return {
        ModalRealizarSorteo: false,
      };

    case LOADER_ACTIVE:
      return {
        ...state,
        loader: true,
      };

    case LOADER_INACTIVE:
      return {
        ...state,
        loader: false,
      };

    default:
      return state;
  }
};
