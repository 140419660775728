export const FORMULARIO_EVENTO = "FORMULARIO_EVENTO";
export const OCULTAR_FORMULARIO_EVENTO = "OCULTAR_FORMULARIO_EVENTO";
export const AGREGAR_EVENTO = "AGREGAR_EVENTO";
export const VALIDAR_FORMULARIO = "VALIDAR_FORMULARIO";
export const VALIDAR_FORMULARIO_OCULTAR = "VALIDAR_FORMULARIO_OCULTAR";
export const VALIDAR_FORMULARIO_EMAIL = "VALIDAR_FORMULARIO_EMAIL";
export const EVENTO_ACTUAL = "EVENTO_ACTUAL";
export const CARGAR_EVENTOS = "CARGAR_EVENTOS";
export const ELIMINAR_EVENTO = "ELIMINAR_EVENTO";
export const ABRIR_EDICION_EVENTO = "ABRIR_EDICION_EVENTO";
export const CERRAR_EDICION_EVENTO = "CERRAR_EDICION_EVENTO";
export const ABRIR_ADMINISTRACION_EVENTO = "ABRIR_ADMINISTRACION_EVENTO";
export const CERRAR_ADMINISTRACION_EVENTO = "CERRAR_ADMINISTRACION_EVENTO";
export const EDITAR_EVENTO = "EDITAR_EVENTO";
export const EVENTOS_MARCA = "EVENTOS_MARCA";

export const TAREAS_PROYECTO = "TAREAS_PROYECTO";
export const AGREGAR_TAREA = "AGREGAR_TAREA";
export const VALIDAR_TAREA = "VALIDAR_TAREA";

export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const USUARIO_AUTENTICADO = "USUARIO_AUTENTICADO";

export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const USER_LOG = "USER_LOG";
export const PASSWORD_LOG = "PASSWORD_LOG";
export const CERRAR_SESION = "CERRAR_SESION";
export const OBTENER_TOKEN = "OBTENER_TOKEN";

export const MARCA_ACTUAL = "MARCA_ACTUAL";

export const CARGAR_ENCUESTAS = "CARGAR_ENCUESTAS";
export const RECARGAR_ENCUESTAS = "RECARGAR_ENCUESTAS";
export const FORMULARIO_ENCUESTAS = "FORMULARIO_ENCUESTAS";
export const AGREGAR_ENCUESTA = "AGREGAR_ENCUESTA";
export const ELIMINAR_ENCUESTA = "ELIMINAR_ENCUESTA";
export const ABRIR_EDICION_ENCUESTA = "ABRIR_EDICION_ENCUESTA";
export const CERRAR_EDICION_ENCUESTA = "CERRAR_EDICION_ENCUESTA";
export const ABRIR_AGREGAR_ENCUESTA = "ABRIR_AGREGAR_ENCUESTA";
export const CERRAR_AGREGAR_ENCUESTA = "CERRAR_AGREGAR_ENCUESTA";
export const ENCUESTA_ACTUAL = "ENCUESTA_ACTUAL";
export const EDITAR_ENCUESTA = "EDITAR_ENCUESTA";

export const CARGAR_ITEMS = "CARGAR_ITEMS";
export const FORMULARIO_ITEMS = "FORMULARIO_ITEMS";
export const AGREGAR_ITEM = "AGREGAR_ITEM";
export const ELIMINAR_ITEM = "ELIMINAR_ITEM";
export const ABRIR_EDICION_ITEM = "ABRIR_EDICION_ITEM";
export const CERRAR_EDICION_ITEM = "CERRAR_EDICION_ITEM";
export const ABRIR_AGREGAR_ITEM = "ABRIR_AGREGAR_ITEM";
export const CERRAR_AGREGAR_ITEM = "CERRAR_AGREGAR_ITEM";
export const ITEM_ACTUAL = "ITEM_ACTUAL";
export const EDITAR_ITEM = "EDITAR_ITEM";

export const CARGAR_INSCRITOS = "CARGAR_INSCRITOS";
export const RECARGAR_INSCRITOS = "RECARGAR_INSCRITOS";
export const CONTAR_INSCRITOS = "CONTAR_INSCRITOS";
export const INSCRITO_ACTUAL = "INSCRITO_ACTUAL";
export const ELIMINAR_INSCRITO = "ELIMINAR_INSCRITO";
export const EDITAR_INSCRITO = "EDITAR_INSCRITO";
export const OPEN_MODAL_EDITAR_INSCRITO = "OPEN_MODAL_EDITAR_INSCRITO";
export const OPEN_MODAL_ELIMINAR_INSCRITO = "OPEN_MODAL_ELIMINAR_INSCRITO";
export const CLOSE_MODAL_EDICION_GUARDADA = "CLOSE_MODAL_EDICION_GUARDADA";

export const LOADER_ACTIVE = "LOADER_ACTIVE";
export const LOADER_INACTIVE = "LOADER_INACTIVE";
export const QUITAR_NUEVO = "QUITAR_NUEVO";

export const ABRIR_SORTEO = "ABRIR_SORTEO";
export const CERRAR_SORTEO = "CERRAR_SORTEO";
export const AGREGAR_SORTEO = "AGREGAR_SORTEO";
export const NOAGREGAR_SORTEO = "NOAGREGAR_SORTEO";
export const ABRIR_GANADORES = "ABRIR_GANADORES";
export const CERRAR_GANADORES = "CERRAR_GANADORES";
export const ELIMINAR_GANADORES = "ELIMINAR_GANADORES";
export const CARGAR_GANADORES = "CARGAR_GANADORES";
export const RECARGAR_GANADORES = "RECARGAR_GANADORES";
export const ENVIADOS_GANADORES = "ENVIADOS_GANADORES";
export const NOENVIADOS_GANADORES = "NOENVIADOS_GANADORES";
export const LIMPIAR_GANADORES = "LIMPIAR_GANADORES";
export const ABRIR_TOTAL = "ABRIR_TOTAL";

export const OPEN_MODAL_EDITAR_ENCUESTA = "OPEN_MODAL_EDITAR_ENCUESTA";
export const CLOSE_MODAL_EDITAR_ENCUESTA = "CLOSE_MODAL_EDITAR_ENCUESTA";
export const OPEN_MODAL_AGREGAR_ENCUESTA = "OPEN_MODAL_AGREGAR_ENCUESTA";
export const CLOSE_MODAL_AGREGAR_ENCUESTA = "CLOSE_MODAL_AGREGAR_ENCUESTA";
export const OPEN_MODAL_ESTADISTICAS = "OPEN_MODAL_ESTADISTICAS";
export const CLOSE_MODAL_ESTADISTICAS = "CLOSE_MODAL_ESTADISTICAS";

export const OPEN_MODAL_EDITAR_ITEM = "OPEN_MODAL_EDITAR_ITEM";
export const CLOSE_MODAL_EDITAR_ITEM = "CLOSE_MODAL_EDITAR_ENCUESTA";
export const OPEN_MODAL_AGREGAR_ITEM = "OPEN_MODAL_AGREGAR_ITEM";
export const CLOSE_MODAL_AGREGAR_ITEM = "CLOSE_MODAL_AGREGAR_ENCUESTA";
export const OPEN_MODAL_ELIMINAR_ENCUESTA = "OPEN_MODAL_ELIMINAR_ENCUESTA";
export const CLOSE_MODAL_ELIMINAR_ENCUESTA = "CLOSE_MODAL_ELIMINAR_ENCUESTA";
export const OPEN_MODAL_REALIZAR_SORTEO = "OPEN_MODAL_REALIZAR_SORTEO";
export const CLOSE_MODAL_REALIZAR_SORTEO = "CLOSE_MODAL_REALIZAR_SORTEO";

export const STOP_RELOAD = "STOP_RELOAD";

export const ESTADISTICA_ACTUAL = "ESTADISTICA_ACTUAL";
export const ESTADISTICA_TOTAL = "ESTADISTICA_TOTAL";
