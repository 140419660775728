import React, { Fragment, useContext, useEffect, useState } from "react";
import Evento from "./Evento";
import EditarEvento from "./EditarEvento";
import AdministrarEvento from "./AdministrarEvento";
import EliminarEvento from "./EliminarEvento";
import Modal from "react-modal";

// Importando el context de las dependencias
import eventoContext from "../../context/eventos/eventoContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
  },
};

const ListadoTareas = () => {
  // Extraer eventos del state inicial
  const eventosContext = useContext(eventoContext);
  const {
    eventos,
    editarevento,
    administrarevento,
    eventoactual,
    actionevento,

    cargarEventos,
    eventoActual,
    cerrarEdicionEvento,
    abrirAdminEvento,
  } = eventosContext;

  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Si el usuario se autentico y se genero el token se pregunta por los eventos creados
  useEffect(() => {
    if (token) {
      cargarEventos(token);
    }

    if (actionevento) {
      cargarEventos(token);
    }
  }, [token, actionevento]);

  // useState del popup
  const [getModalEliminar, setModalEliminar] = useState(false);
  const [getModalEditar, setModalEditar] = useState(false);

  // Elimina un evento
  const onClickEliminar = (identificador) => {
    eventoActual(identificador);
    setModalEliminar(true);
  };

  // Editar un evento
  const onClickEditar = (identificador) => {
    eventoActual(identificador);
    setModalEditar(true);
  };

  // Administrar eventos
  const administrarEvento = (identificador) => {
    eventoActual(identificador);
    abrirAdminEvento();
    cerrarEdicionEvento();
  };

  // Modals
  var subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#00000";
  }

  // Funcion cerrar eliminar evento
  const FCloseModalEliminarEvento = () => {
    setModalEliminar(false);
  };

  // Funcion cerrar editar evento
  const FCloseModalEditarEvento = () => {
    setModalEditar(false);
  };

  return (
    <Fragment>
      <h2>Eventos</h2>

      {administrarevento && eventoactual !== "" ? (
        <AdministrarEvento eventoactual={eventoactual}></AdministrarEvento>
      ) : null}

      {!editarevento && !administrarevento && !actionevento ? (
        <Fragment>
          <ul className="listado-tareas">
            {eventos.lenght === 0 ? (
              <li className="tarea">
                <p>No hay tareas</p>
              </li>
            ) : (
              eventos.map((evento, keyIndex) => (
                <Evento
                  evento={evento}
                  number={keyIndex}
                  key={keyIndex}
                  onClickEliminar={onClickEliminar}
                  onClickEditar={onClickEditar}
                  administrarEvento={administrarEvento}
                />
              ))
            )}
          </ul>
        </Fragment>
      ) : null}

      <Modal
        isOpen={getModalEliminar}
        onRequestClose={FCloseModalEliminarEvento}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Eliminar Evento</h2>
        {getModalEliminar !== false ? (
          <EliminarEvento
            eventoactual={eventoactual}
            FCloseModalEliminarEvento={FCloseModalEliminarEvento}
          ></EliminarEvento>
        ) : null}
      </Modal>

      <Modal
        isOpen={getModalEditar}
        onRequestClose={FCloseModalEditarEvento}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("app")}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Editar Evento</h2>
        {getModalEditar !== false ? (
          <EditarEvento
            eventoactual={eventoactual}
            FCloseModalEditarEvento={FCloseModalEditarEvento}
          ></EditarEvento>
        ) : null}
      </Modal>
    </Fragment>
  );
};

export default ListadoTareas;
