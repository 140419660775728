import React, { useContext } from "react";

// Importando el context de las dependencias
import encuestasContext from "../../context/encuestas/encuestaContext";
import ItemContext from "../../context/items/itemContext";
import sorteoContext from "../../context/sorteos/sorteosContext";
import estadisticasContext from "../../context/estadisticas/estadisticasContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const Encuesta = ({ encuesta }) => {
  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const {
    abrirEdicionEncuesta,

    cerrarEdicionEncuesta,
    openModalEditarEncuesta,
    openModalEliminarEncuesta,
    encuestaActual,
  } = encuestaContext;

  // Obtener el state de sorteos context
  const sorteosContext = useContext(sorteoContext);
  const {
    sorteo,

    abrirSorteo,
    cerrarSorteo,
  } = sorteosContext;

  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de encuestas context
  const itemsContext = useContext(ItemContext);
  const { abrirAgregarItem } = itemsContext;

  // Obtener el state de estadisticas context
  const estadisticaContext = useContext(estadisticasContext);
  const {
    estadisticaActual,
    totalEstadistica,

    openModalEstadisticas,
  } = estadisticaContext;

  // Funcion para abrir editar encuesta
  const llamarEditarEncuesta = () => {
    abrirEdicionEncuesta();
    encuestaActual(encuesta);
    openModalEditarEncuesta();
  };

  // Funcion para abrir agregar encuesta
  const llamarAgregarEncuesta = () => {
    abrirAgregarItem();
    cerrarEdicionEncuesta();
    encuestaActual(encuesta);
  };

  // Funcion para abrir sorteo
  const llamarRealizarSorteo = () => {
    encuestaActual(encuesta);
    if (!sorteo) {
      cerrarSorteo();
    }
    abrirSorteo();
  };

  // Funcion llamar estadisticas
  const llamarEstadisticas = () => {
    encuestaActual(encuesta);
    totalEstadistica(encuesta.id, token);
    estadisticaActual(encuesta.id, token);
    openModalEstadisticas();
  };

  // Elimina un evento
  const llamarEliminar = () => {
    encuestaActual(encuesta);
    openModalEliminarEncuesta();
  };

  return (
    <li key={encuesta.keyIndex} className="tarea sombra" id={encuesta.id}>
      <p>{encuesta.title}</p>
      <div className="estado">
        {encuesta.status === 1 ? (
          <button type="button" className="completo">
            Activo
          </button>
        ) : (
          <button type="button" className="incompleto">
            Inactivo
          </button>
        )}

        {encuesta.published === 1 ? (
          <button type="button" className="completo">
            Publicada
          </button>
        ) : (
          <button type="button" className="incompleto">
            No publicada
          </button>
        )}
      </div>

      <div className="acciones">
        <button
          type="button"
          className="btn btn-primario"
          onClick={() => llamarEditarEncuesta()}
        >
          Editar
        </button>

        <button
          type="button"
          className="btn btn-secundario"
          onClick={() => llamarAgregarEncuesta()}
        >
          Administrar Items
        </button>

        <button
          type="button"
          className="btn btn-terciario btn-estadistica"
          onClick={() => llamarEstadisticas(encuesta.id)}
        >
          Estadisticas
        </button>

        <button
          type="button"
          className="btn btn-terciario btn-rifa"
          onClick={() => llamarRealizarSorteo()}
        >
          Sorteo
        </button>

        <button
          type="button"
          className="btn btn-terciario"
          onClick={() => llamarEliminar()}
        >
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default Encuesta;
