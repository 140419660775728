import React, { Fragment, useContext, useState, useEffect } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import rifaContext from "../../context/rifa/rifaContext";

const Total = ({ eventoactual, nombreactual }) => {
  console.log("nombre", nombreactual);
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de rifa context
  const rifasContext = useContext(rifaContext);
  const {
    sorteos,
    total,
    actiontotal,

    agregarGanadores,
    cargarGanadores,
    eliminarGanadores,
    cerrarSorteo,
  } = rifasContext;

  // Se extrae los ganadores desde el endpoint
  useEffect(() => {
    cargarGanadores(eventoactual, "", token);
  }, [actiontotal]);

  // Extraer datos de los ganadores desde el state
  const listadototal = total.items;

  // Se crea el state para el formulario
  const [getTotal, setTotal] = useState([
    {
      ids: "",
    },
  ]);

  // Se crea la funcion que toma el evento onchange del formulario
  const onChangeTotal = (e) => {
    const checked = e.target.checked;

    const arraytotal = getTotal;

    // Se evalua el estado de los checkbox

    if (checked) {
      setTotal([...getTotal, { [e.target.name]: e.target.value }]);
    } else {
      const evaldeleteid = Object.values(arraytotal).filter(
        (item) => item.ids !== e.target.value
      );

      setTotal(evaldeleteid);
    }
  };

  // Cuando el usuario envia los total
  const onSubmitTotal = (e) => {
    e.preventDefault();

    // Se eliminan los datos vacios

    const arraytotal = getTotal;

    const evaldeleteid = Object.values(arraytotal).filter(
      (item) => item.ids !== ""
    );

    setTotal(evaldeleteid);

    // Se crea el querystring

    const details = getTotal;
    const stringDataGanadores = details
      .map(({ ids }) => "ids[]=" + `${ids}`)
      .join("&");

    // Se limpia el querystring de objetos vacios &
    // Agregar al state y enviar el endpoint
    eliminarGanadores(stringDataGanadores.replace("ids[]=&", ""), token);
  };

  return (
    <Fragment>
      <h4>Consolidado</h4>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitTotal}>
        <ul className="listado-proyectos">
          {total.status ? (
            listadototal?.length ? (
              listadototal?.length > 0 ? (
                listadototal.map((ganador, keyIndex) => (
                  <li className="tarea sombra" key={keyIndex}>
                    <p>
                      <strong>Nombre: </strong>
                      {ganador.donor.fullname} <br></br>{" "}
                      <strong>Telefóno:</strong> {ganador.donor.cellphone}
                      <br></br> <strong>Id:</strong> {ganador.id}
                      <br></br> <strong>UserId:</strong> {ganador.donorId}
                      <br></br> <strong>Documento:</strong>{" "}
                      {ganador.donor.documentType}.{" "}
                      {ganador.donor.documentNumber} <br></br>{" "}
                      <strong>Correo:</strong> {ganador.donor.email}
                    </p>

                    <div className="acciones">
                      <input
                        type="checkbox"
                        id="cbox2"
                        value={ganador.id}
                        name="ids"
                        onChange={onChangeTotal}
                      />{" "}
                      <label htmlFor="cbox2">Eliminar ganador </label>
                    </div>
                  </li>
                ))
              ) : (
                <li className="tarea sombra">
                  <div className="acciones">
                    Aun no hay total de ganadores en esta encuesta
                  </div>
                </li>
              )
            ) : null
          ) : null}
        </ul>
        {listadototal?.length > 0 ? (
          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Eliminar Ganadores"
          />
        ) : null}
        <br></br>
      </form>
      <br></br>
    </Fragment>
  );
};

export default Total;
