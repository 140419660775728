import React, { Fragment, useContext, useState } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import sorteoContext from "../../context/sorteos/sorteosContext";

const Ganadores = () => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state de sorteos context
  const sorteosContext = useContext(sorteoContext);
  const {
    sorteos,
    enviadosganadores,

    agregarGanadores,
  } = sorteosContext;

  // Extraer datos de los ganadores desde el state
  const listadogandores = sorteos.items;

  // Se crea el state para el formulario
  const [getGanadores, setGanadores] = useState({
    items: [
      {
        userId: "",
        promoterId: "",
        pollId: "",
        raffleType: "normal",
      },
    ],
  });

  // Cuando el usuario envia los ganadores
  const onSubmitGanadores = (e) => {
    e.preventDefault();

    const arrayganadores = getGanadores.items;

    // Se eliminan los datos vacios

    const evaldeleteid = arrayganadores.filter((item) => item.userId !== "");

    console.log("los ganadores antes", evaldeleteid);

    setGanadores({
      items: evaldeleteid,
    });

    const ganadoresEnviar = {
      items: evaldeleteid,
    };

    // Agregar al state
    agregarGanadores(ganadoresEnviar, token);
  };

  console.log("Listado", listadogandores.length);

  return (
    <Fragment>
      <h4>Ganadores 00</h4>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitGanadores}>
        <ul className="listado-proyectos">
          {listadogandores.length !== undefined ? (
            listadogandores.length > 0 ? (
              listadogandores.map((ganador, keyIndex) => (
                <li className="tarea sombra" key={keyIndex}>
                  <p>
                    <strong>Nombre: </strong>
                    {ganador.fullname}
                    <br></br> <strong>Telefóno:</strong> {ganador.cellphone}
                    <br></br> <strong>Id:</strong> {ganador.id}
                    <br></br> <strong>Documento:</strong> {ganador.documenttype}
                    . {ganador.documentNumber}
                    <br></br> <strong>Correo:</strong> {ganador.email}
                  </p>
                </li>
              ))
            ) : (
              <li className="tarea sombra">
                <div className="acciones">
                  Aun no hay ganadores en esta encuesta
                </div>
              </li>
            )
          ) : null}
        </ul>

        {enviadosganadores ? (
          <p className="mensaje error">
            Los ganadores no fueron enviados intente nuevamente
          </p>
        ) : null}
      </form>
      <br></br>
    </Fragment>
  );
};

export default Ganadores;
