import React, { useContext } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import InscritosContext from "../../context/inscritos/inscritosContext";

const Inscrito = ({
  inscrito,
  number,
  FOpenModalEditarInscrito,
  FOpenModalEliminarInscrito,
}) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer data de los inscritos del state inicial
  const inscritosContext = useContext(InscritosContext);
  const { inscritoActual } = inscritosContext;

  // Funcion abrir edicion de usuario
  const starEditUser = (inscrito) => {
    /* inscritoActual(token, inscrito); */
    inscritoActual(token, inscrito.id);
    FOpenModalEditarInscrito(inscrito);
  };

  // Funcion abrir eliminar de usuario
  const starDeleteUser = (inscrito) => {
    inscritoActual(token, inscrito.id);
    FOpenModalEliminarInscrito(inscrito);
  };

  return (
    <li className="usuario" id={inscrito.id} key={number}>
      <p>
        {inscrito.name}
        <br></br>
        {inscrito.surname}
      </p>
      <p>{inscrito.cellphone}</p>
      <p>
        {inscrito.documentType}. {inscrito.documentNumber}
      </p>
      <p>{inscrito.email}</p>

      <p className="buttons">
        <button
          type="button"
          className="btn btn-primario"
          onClick={() => starEditUser(inscrito)}
        >
          Editar
        </button>

        <button
          type="button"
          className="btn btn-terciario"
          onClick={() => starDeleteUser(inscrito)}
        >
          Eliminar
        </button>
      </p>
    </li>
  );
};

export default Inscrito;
