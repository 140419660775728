import {
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  OBTENER_USUARIO,
  USER_LOG,
  PASSWORD_LOG,
  OBTENER_TOKEN,
  CERRAR_SESION,
  USUARIO_AUTENTICADO,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
} from "../../types/";

export default (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
      localStorage.setItem("token", action.payload);
      localStorage.setItem("autenticado", "true");
      console.log("LOGIN_EXITOSO", action.type);
      return {
        ...state,
        autenticado: true,
        mensaje: null,
        cargando: false,
      };
    case LOGIN_ERROR:
      localStorage.removeItem("token");
      localStorage.removeItem("autenticado");
      console.log("LOGIN_ERROR", action, state);
      return {
        ...state,
        errorformulario: true,
        mensaje: action.payload,
        cargando: false,
      };
    case USER_LOG:
      return {
        ...state,
        usernamelog: action.payload,
      };
    case PASSWORD_LOG:
      return {
        ...state,
        usernamepass: action.payload,
      };
    case OBTENER_USUARIO:
      return {
        ...state,
        usuariodata: action.payload,
      };
    case OBTENER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case CERRAR_SESION:
      localStorage.removeItem("token");
      localStorage.removeItem("autenticado");
      return {
        ...state,
        autenticado: false,
      };
    case USUARIO_AUTENTICADO:
      return {
        ...state,
        autenticado: true,
        mensaje: null,
        cargando: false,
      };
    case LOADER_ACTIVE:
      return {
        loader: true,
      };
    case LOADER_INACTIVE:
      return {
        loader: false,
      };

    default:
      return state;
  }
};
