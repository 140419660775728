import React, { useState, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import InscritosContext from "../../context/inscritos/inscritosContext";

const FormInscritos = () => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer data de los inscritos del state inicial
  const inscritosContext = useContext(InscritosContext);
  const {
    errorformulario,
    numeroinscritos,

    mostrarError,
    buscarInscritosEmail,
    buscarInscritosNombre,
    buscarInscritosDocumento,
  } = inscritosContext;

  // State para la busqueda
  const [getBusqueda, setBusqueda] = useState({});

  // Lee los contenidos del input
  const onChangeBusqueda = (e) => {
    setBusqueda({
      ...getBusqueda,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un encuesta

  const { nombre, email, documento } = getBusqueda;

  const onSubmitBusquedaEmail = (e) => {
    e.preventDefault();

    if (email === "") {
      mostrarError();
    }

    buscarInscritosEmail(token, email);
  };

  const onSubmitBusquedaNombre = (e) => {
    e.preventDefault();

    if (nombre === "") {
      mostrarError();
    }

    buscarInscritosNombre(token, nombre);
  };

  const onSubmitBusquedaDocumento = (e) => {
    e.preventDefault();

    if (documento === "") {
      mostrarError();
    }

    buscarInscritosDocumento(token, documento);
  };

  return (
    <div className="formulario formulario_busqueda">
      <Tabs>
        <TabList>
          <Tab>Busqueda por email</Tab>
          <Tab>Busqueda por nombre</Tab>
          <Tab>Busqueda por documento</Tab>
        </TabList>

        <TabPanel>
          <form className="content-forms" onSubmit={onSubmitBusquedaEmail}>
            <h5>Realice busquedas de usuarios inscritos por email</h5>
            <div className="contenedor-input">
              <input
                type="email"
                className="input-text"
                placeholder="Email"
                name="email"
                onChange={onChangeBusqueda}
              />
            </div>

            <div className="contenedor-input">
              <input
                type="submit"
                className="btn btn-primario btn-submit btn-block"
                value="Realizar Busqueda"
              />
            </div>

            {errorformulario ? (
              <p className="mensaje error">Todos los campos son obligatorios</p>
            ) : null}
          </form>
        </TabPanel>

        <TabPanel>
          <form className="content-forms" onSubmit={onSubmitBusquedaNombre}>
            <h5>
              Realice busquedas de usuarios inscritos por nombre{" "}
              {numeroinscritos}
            </h5>
            <div className="contenedor-input">
              <input
                type="text"
                className="input-text"
                placeholder="Nombre del usuario"
                name="nombre"
                onChange={onChangeBusqueda}
              />
            </div>

            <div className="contenedor-input">
              <input
                type="submit"
                className="btn btn-primario btn-submit btn-block"
                value="Realizar Busqueda"
              />
            </div>

            {errorformulario ? (
              <p className="mensaje error">Todos los campos son obligatorios</p>
            ) : null}
          </form>
        </TabPanel>

        <TabPanel>
          <form className="content-forms" onSubmit={onSubmitBusquedaDocumento}>
            <h5>Realice busquedas de usuarios inscritos por documento</h5>
            <div className="contenedor-input">
              <input
                type="text"
                className="input-text"
                placeholder="Documento"
                name="documento"
                onChange={onChangeBusqueda}
              />
            </div>

            <div className="contenedor-input">
              <input
                type="submit"
                className="btn btn-primario btn-submit btn-block"
                value="Realizar Busqueda"
              />
            </div>

            {errorformulario ? (
              <p className="mensaje error">El campo esta vacio</p>
            ) : null}
          </form>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default FormInscritos;
