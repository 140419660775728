import React, { useContext, useState } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";

// Importando el context de las dependencias
import encuestasContext from "../../context/encuestas/encuestaContext";

const EditarEncuesta = ({ eventoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state del formulario
  const encuestaContext = useContext(encuestasContext);
  const {
    encuestaactual,

    editarEncuesta,
    closeModalEditarEncuesta,
  } = encuestaContext;

  // State para encuesta
  const [encuesta, guardarEncuesta] = useState({
    titulo: encuestaactual.title,
    descripcion: encuestaactual.description,
    idevento: eventoactual,
    idencuesta: encuestaactual.id,
    estado: encuestaactual.status.toString(),
    publicada: encuestaactual.published.toString(),
  });

  // Extraer nombre de encuesta
  const { titulo, descripcion, idevento, idencuesta, estado, publicada } =
    encuesta;

  // Lee los contenidos del input
  const onChangeEncuesta = (e) => {
    guardarEncuesta({
      ...encuesta,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un encuesta
  const onSubmitEncuesta = (e) => {
    e.preventDefault();

    // Agregar al state
    editarEncuesta(encuesta, token, idevento, idencuesta);

    // Reiniciar el form
    guardarEncuesta({
      titulo: "",
      estado: "",
      descripcion: "",
    });

    /* closeModalEditarEncuesta(); */
  };

  return (
    <div className="listado-eventos">
      <h4>Editar encuesta: {encuestaactual.title}</h4>

      <form className="formulario-editar-evento" onSubmit={onSubmitEncuesta}>
        <input
          type="text"
          className="input-text"
          placeholder={encuestaactual.title}
          name="titulo"
          value={titulo}
          onChange={onChangeEncuesta}
        />
        <br></br>
        <br></br>
        <input
          type="radio"
          id="activo"
          name="estado"
          value="1"
          onChange={onChangeEncuesta}
          checked={estado === "1"}
        />
        <label htmlFor="activo">Activa</label>
        <input
          type="radio"
          id="inactivo"
          name="estado"
          value="0"
          onChange={onChangeEncuesta}
          checked={estado === "0"}
        />
        <label htmlFor="activo">Inactiva</label>
        <br></br>
        <br></br>
        <input
          type="radio"
          id="activo"
          name="publicada"
          value="1"
          onChange={onChangeEncuesta}
          checked={publicada === "1"}
        />
        <label htmlFor="activo">Publicada</label>
        <input
          type="radio"
          id="inactivo"
          name="publicada"
          value="0"
          onChange={onChangeEncuesta}
          checked={publicada === "0"}
        />
        <label htmlFor="activo">No Publicada</label>
        <br></br>
        <br></br>
        <textarea
          id="descripcion"
          className="input-text"
          placeholder={encuestaactual.description}
          name="descripcion"
          value={descripcion}
          onChange={onChangeEncuesta}
        ></textarea>

        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Guardar Encuesta"
        />

        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Cerrar Edición"
          onClick={() => closeModalEditarEncuesta()}
        />
      </form>
    </div>
  );
};

export default EditarEncuesta;
