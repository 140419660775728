import React, { useContext, useState } from "react";

// Importando el context de las dependencias
import eventoContext from "../../context/eventos/eventoContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const EditarEvento = ({ eventoactual, FCloseModalEditarEvento }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state del formulario
  const eventosContext = useContext(eventoContext);
  const { errorformulario, editarEvento, cerrarEdicionEvento, mostrarError } =
    eventosContext;

  // Extraer datos del evento actual
  const { name, brand, description, id } = eventoactual[0];

  // State para evento
  const [evento, guardarEvento] = useState({
    nombre: name,
    marca: brand,
    descripcion: description,
  });

  // Extraer nombre de evento
  const { nombre, marca, descripcion } = evento;

  // Lee los contenidos del input
  const onChangeEvento = (e) => {
    guardarEvento({
      ...evento,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un evento
  const onSubmitEvento = (e) => {
    e.preventDefault();

    // Validar evento
    if (nombre === "") {
      mostrarError();
      return;
    }

    if (marca === "") {
      mostrarError();
      return;
    }

    if (descripcion === "") {
      mostrarError();
      return;
    }

    // Agregar al state
    editarEvento(evento, token, id);
    cerrarEdicionEvento();

    // Reiniciar el form
    guardarEvento({
      nombre: "",
      marca: "",
      descripcion: "",
    });

    FCloseModalEditarEvento();
  };

  return (
    <div className="listado-eventos content-forms">
      <div className="eventos">
        <h4>Editar evento: {name}</h4>

        <form className="formulario-editar-evento" onSubmit={onSubmitEvento}>
          <input
            type="text"
            className="input-text"
            placeholder={name}
            name="nombre"
            value={nombre}
            onChange={onChangeEvento}
          />
          <br></br>
          <br></br>
          <textarea
            id="descripcion"
            className="input-text"
            placeholder={description}
            name="descripcion"
            value={descripcion}
            onChange={onChangeEvento}
          ></textarea>

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Editar Evento"
          />

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Cancelar Edición"
            onClick={() => FCloseModalEditarEvento()}
          />
          {errorformulario ? (
            <p className="mensaje error">Todos los campos son obligatorios</p>
          ) : null}
          <br></br>
          <br></br>
        </form>
      </div>
    </div>
  );
};

export default EditarEvento;
