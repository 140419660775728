import React, { Fragment, useContext, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Ganadores from "./Ganadores";
import Total from "./Total";
import Loader from "../layout/Loader";

// Importando el context de las dependencias
import rifaContext from "../../context/rifa/rifaContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";
import encuestasContext from "../../context/encuestas/encuestaContext";
import itemsContext from "../../context/items/itemContext";

const Sorteos = ({ eventoactual, nombreactual }) => {
  // Obtener el state de rifas context
  const rifasContext = useContext(rifaContext);
  const {
    errorformulario,
    listaganadores,
    generadosorteo,
    sorteos,
    total,
    loader,

    generarSorteo,
    abrirGanadores,
    cargarGanadores,
    limpiarGanadores,
  } = rifasContext;

  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const { encuestaactual } = encuestaContext;

  // State para el sorteo
  const [sorteo] = useState({
    idevento: eventoactual,
  });

  // Extraer item del state inicial
  const itemContext = useContext(itemsContext);
  const {
    actionsorteo,

    cargarItems,
  } = itemContext;

  // Si el usuario se autentico y se genero el token se pregunta por los encuesta creados
  useEffect(() => {
    if (token) {
      cargarItems(token, encuestaactual);
      /* cargarGanadores(eventoactual, "", token); */
    }
  }, [sorteo, generadosorteo, total]);

  // Extraer datos del sorteo
  const { idevento } = sorteo;

  // Cuando el usuario envia un encuesta
  const onSubmitSorteo = (e) => {
    e.preventDefault();

    // Limpiar ganadores
    limpiarGanadores();

    // Generar sorteo
    generarSorteo(idevento, token);

    // Abrir gandores
    abrirGanadores();
  };

  return (
    <Fragment>
      <Tabs>
        <TabList>
          <Tab>Sorteo</Tab>
          <Tab onClick={(e) => limpiarGanadores()}>Total de Ganadores</Tab>
          {/* {total.results === 0 ? (
            <Fragment></Fragment>
          ) : total.results > 0 ? (
            <Tab onClick={(e) => limpiarGanadores()}>Total de Ganadores</Tab>
          ) : (
            <Tab></Tab>
          )} */}
        </TabList>

        <TabPanel>
          <h2>
            Sorteo <br />
            {nombreactual}
          </h2>
          <h4>Sorteo Final</h4>
          <form className="formulario-nuevo-proyecto" onSubmit={onSubmitSorteo}>
            <input
              type="submit"
              className="btn btn-primario btn-block"
              value="Realizar Sorteo"
            />

            {errorformulario ? (
              <p className="mensaje error">Todos los campos son obligatorios</p>
            ) : null}

            {generadosorteo ? (
              <p className="mensaje error">
                El sorteo no fue generado intente nuevamente
              </p>
            ) : null}

            {loader ? <Loader /> : null}

            <br></br>
            <br></br>
          </form>
          {listaganadores && sorteos.status && !actionsorteo ? (
            <Ganadores eventoactual={eventoactual} idencuesta={""} />
          ) : null}
        </TabPanel>
        <TabPanel>
          <h2>
            Total de Ganadores <br />
            {nombreactual}
          </h2>
          <Total eventoactual={eventoactual} nombreactual={nombreactual} />
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default Sorteos;
