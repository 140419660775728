import React, { useContext } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";

// Importando el context de las dependencias
import encuestasContext from "../../context/encuestas/encuestaContext";

const EliminarEncuesta = ({ eventoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state del formulario
  const encuestaContext = useContext(encuestasContext);
  const {
    encuestaactual,

    closeModalEliminarEncuesta,
    eliminarEncuesta,
  } = encuestaContext;

  const onClickEliminar = (identificador) => {
    eliminarEncuesta(identificador, token);
  };

  return (
    <div className="listado-eventos">
      <div className="modal-eliminar-encuestas">
        <h3>
          Esta seguro de eliminar esta encuesta?:<br></br> Titulo de la
          encuesta: {encuestaactual.title}
        </h3>

        <button
          type="button"
          className="btn alerta-error"
          onClick={() => onClickEliminar(encuestaactual.id)}
        >
          Eliminar Definitivamente
        </button>

        <button
          type="button"
          className="btn"
          onClick={() => closeModalEliminarEncuesta()}
        >
          Salir
        </button>
      </div>
    </div>
  );
};

export default EliminarEncuesta;
