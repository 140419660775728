import React, { useContext } from "react";

// Importando el context de las dependencias
import eventoContext from "../../context/eventos/eventoContext";
import UsuarioContext from "../../context/usuarios/usuarioContext";

const EliminarEvento = ({ eventoactual, FCloseModalEliminarEvento }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Obtener el state del formulario
  const eventosContext = useContext(eventoContext);
  const { eliminarEvento } = eventosContext;

  // Extraer datos del evento actual
  const { name, id } = eventoactual[0];

  const onClickEliminar = () => {
    eliminarEvento(id, token);
    FCloseModalEliminarEvento();
  };

  return (
    <div className="listado-eventos">
      <div className="modal-eliminar-encuestas">
        <h3>
          Esta seguro de eliminar este evento?:<br></br> Titulo del evento:{" "}
          {name}
        </h3>

        <button
          type="button"
          className="btn alerta-error"
          onClick={() => onClickEliminar()}
        >
          Eliminar Definitivamente
        </button>

        <button
          type="button"
          className="btn"
          onClick={() => FCloseModalEliminarEvento()}
        >
          Salir
        </button>
      </div>
    </div>
  );
};

export default EliminarEvento;
