import {
  CARGAR_INSCRITOS,
  RECARGAR_INSCRITOS,
  EDITAR_INSCRITO,
  VALIDAR_FORMULARIO,
  VALIDAR_FORMULARIO_EMAIL,
  VALIDAR_FORMULARIO_OCULTAR,
  CONTAR_INSCRITOS,
  INSCRITO_ACTUAL,
  ELIMINAR_INSCRITO,
  OPEN_MODAL_EDITAR_INSCRITO,
  OPEN_MODAL_ELIMINAR_INSCRITO,
  CLOSE_MODAL_EDICION_GUARDADA,
  STOP_RELOAD,
} from "../../types/";

export default (state, action) => {
  switch (action.type) {
    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };

    case VALIDAR_FORMULARIO_EMAIL:
      return {
        ...state,
        errorformularioemail: true,
      };

    case VALIDAR_FORMULARIO_OCULTAR:
      return {
        ...state,
        errorformulario: false,
      };

    case CARGAR_INSCRITOS:
      return {
        ...state,
        inscritos: action.payload,
        lastkey: action.valuelastkey,
        actioninscritos: true,
      };

    case EDITAR_INSCRITO:
      return {
        ...state,
        actioninscritos: true,
        getModalEdicionGuardada: true,
      };

    case ELIMINAR_INSCRITO:
      return {
        ...state,
        actioninscritos: true,
      };

    case INSCRITO_ACTUAL:
      return {
        ...state,
        inscritoactual: action.payload,
      };

    case CONTAR_INSCRITOS:
      return {
        ...state,
        numeroinscritos: action.payload,
      };

    case RECARGAR_INSCRITOS:
      return {
        ...state,
        inscritos: action.payload,
        lastkey: action.valuelastkey,
        errorformulario: false,
      };

    case OPEN_MODAL_EDITAR_INSCRITO:
      return {
        ...state,
        getModalEliminarInscrito: true,
      };

    case OPEN_MODAL_ELIMINAR_INSCRITO:
      return {
        ...state,
        getModalEditarInscrito: true,
      };

    case CLOSE_MODAL_EDICION_GUARDADA:
      return {
        ...state,
        getModalEdicionGuardada: false,
      };

    case STOP_RELOAD:
      return {
        ...state,
        actioninscritos: false,
      };

    default:
      return state;
  }
};
