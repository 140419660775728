import React from "react";

const Barloader = () => {
  return (
    <div className="spinner">
      <div className="dot1"></div>
      <div className="dot2"></div>
    </div>
  );
};

export default Barloader;
