import React, { useContext, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import InscritosContext from "../../context/inscritos/inscritosContext";

const EditarInscrito = ({ FCloseModalEditarInscrito, inscritoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer data de los inscritos del state inicial
  const inscritosContext = useContext(InscritosContext);
  const { editarUser } = inscritosContext;

  // State para encuesta
  const [getInscrito, setInscrito] = useState({
    documentType: inscritoactual.documentType,
    documentNumber: inscritoactual.documentNumber,
    documentIssueDate: inscritoactual.documentIssueDate,
    documentExpiryDate: inscritoactual.documentExpiryDate,
    visaType: inscritoactual.visaType,
    visaNumber: inscritoactual.visaNumber,
    nationality: inscritoactual.nationality,
    name: inscritoactual.name,
    surname: inscritoactual.surname,
    email: inscritoactual.email,
    place: inscritoactual.place,
    address: inscritoactual.address,
    cellphone: inscritoactual.cellphone,
    status: inscritoactual.status.toString(),
    id: inscritoactual.id,
  });

  // Extraer nombre de encuesta
  const {
    documentType,
    documentNumber,
    documentIssueDate,
    documentExpiryDate,
    visaType,
    visaNumber,
    nationality,
    name,
    surname,
    email,
    place,
    address,
    cellphone,
    status,
    id,
  } = getInscrito;

  // Lee los contenidos del input
  const onChangeUsuario = (e) => {
    setInscrito({
      ...getInscrito,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario envia un encuesta
  const onSubmitUsuario = (e) => {
    e.preventDefault();

    // Agregar al state
    editarUser(
      token,
      documentType,
      documentNumber,
      documentIssueDate,
      documentExpiryDate,
      visaType,
      visaNumber,
      nationality,
      name,
      surname,
      email,
      place,
      address,
      cellphone,
      status,
      id
    );

    FCloseModalEditarInscrito();

    // Reiniciar el form
    setInscrito({
      documentType: "",
      documentNumber: "",
      documentIssueDate: "",
      documentExpiryDate: "",
      visaType: "",
      visaNumber: "",
      nationality: "",
      name: "",
      surname: "",
      email: "",
      place: "",
      address: "",
      cellphone: "",
      status: "",
    });
  };

  return (
    <div className="listado-eventos">
      <h4>
        Editar usuario: {inscritoactual.name} {inscritoactual.surname}
      </h4>

      <form className="formulario-editar-evento" onSubmit={onSubmitUsuario}>
        <Tabs>
          <TabList>
            <Tab>Datos Personales</Tab>
            <Tab>Contacto</Tab>
            <Tab>Documentos</Tab>
            {nationality !== "CO" ? <Tab>Documentos Extranjero</Tab> : null}
          </TabList>
          <TabPanel>
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.name}
              name="name"
              value={name}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="surname">Apellido</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.surname}
              name="surname"
              value={surname}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="place">Ciudad</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.place}
              name="place"
              value={place}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="estatus">Estado</label>
            <br></br>
            <br></br>
            <input
              type="radio"
              id="activo"
              name="status"
              value="1"
              onChange={onChangeUsuario}
              checked={status === "1"}
            />
            <label htmlFor="activo">Activo</label>
            <input
              type="radio"
              id="inactivo"
              name="status"
              value="0"
              onChange={onChangeUsuario}
              checked={status === "0"}
            />
            <label htmlFor="inactivo">Inactivo</label>

            <br></br>
            <br></br>
          </TabPanel>
          <TabPanel>
            <label htmlFor="email">Email</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.email}
              name="email"
              value={email}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>

            <label htmlFor="address">Dirección</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.address}
              name="address"
              value={address}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="cellphone">Teléfono</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.cellphone}
              name="cellphone"
              value={cellphone}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
          </TabPanel>
          <TabPanel>
            <label htmlFor="nationality">Nacionalidad</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.nationality}
              name="nationality"
              value={nationality}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="documentType">Tipo de documento</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.documentType}
              name="documentType"
              value={documentType}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="documentNumber">Número de documento</label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.documentNumber}
              name="documentNumber"
              value={documentNumber}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="documentIssueDate">
              Fecha de expedición del documento
            </label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.documentIssueDate}
              name="documentIssueDate"
              value={documentIssueDate}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
            <label htmlFor="documentExpiryDate">
              Fecha de vencimiento del documento
            </label>
            <input
              type="text"
              className="input-text"
              placeholder={inscritoactual.documentExpiryDate}
              name="documentExpiryDate"
              value={documentExpiryDate}
              onChange={onChangeUsuario}
            />
            <br></br>
            <br></br>
          </TabPanel>
          {nationality !== "CO" ? (
            <TabPanel>
              <label htmlFor="visaType">Tipo de Visa</label>
              <input
                type="text"
                className="input-text"
                placeholder={inscritoactual.visaType}
                name="visaType"
                value={visaType}
                onChange={onChangeUsuario}
              />
              <br></br>
              <br></br>
              <label htmlFor="visaType">Número de Visa</label>
              <input
                type="text"
                className="input-text"
                placeholder={inscritoactual.visaNumber}
                name="visaType"
                value={visaNumber}
                onChange={onChangeUsuario}
              />
              <br></br>
              <br></br>
            </TabPanel>
          ) : null}

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Guardar Edición"
          />

          <input
            type="submit"
            className="btn btn-primario btn-block"
            value="Cerrar Edición"
            onClick={() => FCloseModalEditarInscrito()}
          />
        </Tabs>
      </form>
    </div>
  );
};

export default EditarInscrito;
