import {
  AGREGAR_EVENTO,
  FORMULARIO_EVENTO,
  VALIDAR_FORMULARIO,
  EVENTO_ACTUAL,
  ELIMINAR_EVENTO,
  STOP_RELOAD,
  CARGAR_EVENTOS,
  ABRIR_EDICION_EVENTO,
  CERRAR_EDICION_EVENTO,
  ABRIR_ADMINISTRACION_EVENTO,
  CERRAR_ADMINISTRACION_EVENTO,
  EDITAR_EVENTO,
  EVENTOS_MARCA,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_EVENTO:
      return {
        ...state,
        formulario: true,
      };

    case AGREGAR_EVENTO:
      return {
        ...state,
        formulario: false,
        errorformulario: false,
        actionevento: true,
        eventos: [...state.eventos, action.dispatch.item],
      };

    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };

    case EVENTO_ACTUAL:
      return {
        ...state,
        eventoactual: state.eventos.filter(
          (evento) => evento.id === action.payload
        ),
      };

    case CARGAR_EVENTOS:
      return {
        ...state,
        eventos: action.payload,
        estadocarga: true,
        actionevento: false,
        loadstatus: action.status,
      };

    case EDITAR_EVENTO:
      return {
        ...state,
        actionevento: true,
      };

    case EVENTOS_MARCA:
      return {
        ...state,
        eventosmarca: state.eventos.filter(
          (evento) => evento.brand === action.payload
        ),
      };

    case ABRIR_EDICION_EVENTO:
      return {
        ...state,
        editarevento: true,
      };

    case CERRAR_EDICION_EVENTO:
      return {
        ...state,
        editarevento: false,
      };

    case ABRIR_ADMINISTRACION_EVENTO:
      return {
        ...state,
        administrarevento: true,
      };

    case CERRAR_ADMINISTRACION_EVENTO:
      return {
        ...state,
        administrarevento: false,
      };

    case ELIMINAR_EVENTO:
      return {
        ...state,
        eventos: state.eventos.filter((evento) => evento.id !== action.payload),
        evento: null,
        actionevento: true,
      };

    case LOADER_ACTIVE:
      return {
        ...state,
        loader: true,
      };

    case LOADER_INACTIVE:
      return {
        ...state,
        loader: true,
      };

    case STOP_RELOAD:
      return {
        ...state,
        actionitem: false,
        actionevento: false,
      };

    default:
      return state;
  }
};
