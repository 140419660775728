import React, { useReducer } from "react";
import axios from "axios";

import sorteoContext from "./sorteosContext";
import sorteoReducer from "./sorteosReducer";

import {
  ABRIR_SORTEO,
  CERRAR_SORTEO,
  AGREGAR_SORTEO,
  NOAGREGAR_SORTEO,
  VALIDAR_FORMULARIO,
  ABRIR_GANADORES,
  CERRAR_GANADORES,
  ELIMINAR_GANADORES,
  RECARGAR_GANADORES,
  CARGAR_GANADORES,
  ENVIADOS_GANADORES,
  NOENVIADOS_GANADORES,
  LIMPIAR_GANADORES,
  STOP_RELOAD,
  LOADER_ACTIVE,
  LOADER_INACTIVE,
  OPEN_MODAL_REALIZAR_SORTEO,
  CLOSE_MODAL_REALIZAR_SORTEO,
} from "../../types";

const SorteoState = (props) => {
  const initialState = {
    sorteos: [{}],
    sorteo: false,
    total: [{}],
    actiontotal: false,
    errorformulario: false,
    listaganadores: false,
    ganadores: [{}],
    enviadosganadores: false,
    generadosorteo: false,
    actionsorteo: false,
    loader: false,
    ModalRealizarSorteo: false,
  };

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(sorteoReducer, initialState);

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });
  };

  // Selecciona el evento que el usuario dio click
  const abrirSorteo = () => {
    dispatch({
      type: ABRIR_SORTEO,
    });
  };

  // Selecciona el evento que el usuario dio click
  const cerrarSorteo = () => {
    dispatch({
      type: CERRAR_SORTEO,
    });
  };

  // Funcion para eliminar ganadores
  const eliminarGanadores = (stringDataGanadores, passtoken) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "delete",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "raffle-winner/batch-delete/?" +
        stringDataGanadores,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: ELIMINAR_GANADORES,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });

    setTimeout(() => {
      dispatch({
        type: RECARGAR_GANADORES,
      });
    }, 3000);
  };

  // Función para generar el sorteo
  const generarSorteo = (
    ganadores,
    idevento,
    idencuesta,
    iditem,
    passtoken
  ) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;
    dispatch({
      type: LOADER_ACTIVE,
    });

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "raffle/?promoterId=" +
        idevento +
        "&pollId=" +
        idencuesta +
        "&pollItemId=" +
        iditem +
        "&limit=" +
        ganadores,
      timeout: 60000,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: AGREGAR_SORTEO,
          payload: response.data,
        });

        dispatch({
          type: LOADER_INACTIVE,
        });
      })
      .catch(function (error) {
        /* console.log('error', error.response); */
        dispatch({
          type: NOAGREGAR_SORTEO,
        });

        dispatch({
          type: LOADER_INACTIVE,
        });
      });
    setTimeout(() => {
      dispatch({
        type: RECARGAR_GANADORES,
      });
    }, 3000);
  };

  // Función cargar ganadores
  const cargarGanadores = (idevento, idencuesta, passtoken) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "raffle-winner/?promoterId=" +
        idevento +
        "&pollId=" +
        idencuesta +
        "&raffleType=normal&order=asc&limit=40",
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: CARGAR_GANADORES,
          payload: response.data,
        });
      })
      .catch(function (error) {
        /* console.log(error.response); */
      });
  };

  // Funcion para agregar ganadores
  const agregarGanadores = (ganadores, passtoken) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    dispatch({
      type: LOADER_ACTIVE,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_REST_API_SOURCE + "raffle-winner/batch-create",
      timeout: 60000,
      headers: {
        Authorization: token,
      },
      data: ganadores,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: ENVIADOS_GANADORES,
        });

        dispatch({
          type: LOADER_INACTIVE,
        });
      })
      .catch(function (error) {
        /* console.log(error.response); */
        dispatch({
          type: NOENVIADOS_GANADORES,
        });

        dispatch({
          type: LOADER_INACTIVE,
        });
      });
  };

  // Funcion para abrir ganadores
  const abrirGanadores = () => {
    dispatch({
      type: ABRIR_GANADORES,
    });
  };

  // Funcion limpiar ganadores
  const limpiarGanadores = () => {
    dispatch({
      type: LIMPIAR_GANADORES,
    });
  };

  // Funcion para cerrar ganadores
  const cerrarGanadores = () => {
    dispatch({
      type: CERRAR_GANADORES,
    });
  };

  // Funcion para detener el loop de acciones
  const detenerAction = () => {
    dispatch({
      type: STOP_RELOAD,
    });
  };

  // Abrir el modal de realizar sorteo
  const openModalRealizarSorteo = () => {
    dispatch({
      type: OPEN_MODAL_REALIZAR_SORTEO,
    });
  };

  // Cerrar el modal de realizar sorteo
  const closeModalRealizarSorteo = () => {
    dispatch({
      type: CLOSE_MODAL_REALIZAR_SORTEO,
    });
  };

  return (
    <sorteoContext.Provider
      value={{
        sorteos: state.sorteos,
        sorteo: state.sorteo,
        total: state.total,
        errorformulario: state.errorformulario,
        listaganadores: state.listaganadores,
        ganadores: state,
        actionsorteo: state.actionsorteo,
        actiontotal: state.actiontotal,
        enviadosganadores: state.enviadosganadores,
        generadosorteo: state.generadosorteo,
        loader: state.loader,
        ModalRealizarSorteo: state.ModalRealizarSorteo,

        abrirSorteo,
        cerrarSorteo,
        mostrarError,
        generarSorteo,
        abrirGanadores,
        cerrarGanadores,
        agregarGanadores,
        eliminarGanadores,
        cargarGanadores,
        limpiarGanadores,
        detenerAction,
        openModalRealizarSorteo,
        closeModalRealizarSorteo,
      }}
    >
      {props.children}
    </sorteoContext.Provider>
  );
};

export default SorteoState;
