import React from "react";

// Import Router
import { Link } from "react-router-dom";

const Evento = ({
  evento,
  number,
  onClickEliminar,
  administrarEvento,
  onClickEditar,
}) => {
  return (
    <li className="tarea sombra" id={evento.id} key={number}>
      <p>{evento.name}</p>

      <div className="acciones">
        <button
          type="button"
          className="btn btn-primario"
          onClick={() => onClickEditar(evento.id)}
        >
          Editar
        </button>

        <button
          type="button"
          className="btn btn-secundario"
          onClick={() => administrarEvento(evento.id)}
        >
          Administrar Encuestas
        </button>

        <button type="button" className="btn btn-terciario btn-rifa">
          <Link to={"/rifa/" + evento.id + "/" + evento.name}>
            Sorteo Final
          </Link>
        </button>

        <button
          type="button"
          className="btn btn-terciario"
          onClick={() => onClickEliminar(evento.id)}
        >
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default Evento;
