import React, { useReducer } from "react";
import axios from "axios";
import qs from "qs";

import inscritoContext from "./inscritosContext";
import inscritoReducer from "./inscritosReducer";

import {
  CARGAR_INSCRITOS,
  EDITAR_INSCRITO,
  INSCRITO_ACTUAL,
  ELIMINAR_INSCRITO,
  RECARGAR_INSCRITOS,
  VALIDAR_FORMULARIO,
  VALIDAR_FORMULARIO_EMAIL,
  VALIDAR_FORMULARIO_OCULTAR,
  OPEN_MODAL_EDITAR_INSCRITO,
  OPEN_MODAL_ELIMINAR_INSCRITO,
  CLOSE_MODAL_EDICION_GUARDADA,
  STOP_RELOAD,
} from "../../types";

const InscritoState = (props) => {
  // State inicial
  const initialState = {
    inscritos: [{}],
    inscritoactual: [{}],
    numeroinscritos: "",
    errorformulario: false,
    errorformularioemail: false,
    actioninscritos: false,
    lastkey: undefined,
    getModalEdicionGuardada: false,
    getModalEliminarInscrito: false,
    getModalEditarInscrito: false,
  };

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO,
    });

    setTimeout(() => {
      dispatch({
        type: VALIDAR_FORMULARIO_OCULTAR,
      });
    }, 5000);
  };

  const mostrarErrorEmail = () => {
    dispatch({
      type: VALIDAR_FORMULARIO_EMAIL,
    });

    setTimeout(() => {
      dispatch({
        type: VALIDAR_FORMULARIO_OCULTAR,
      });
    }, 5000);
  };

  // Cargar inscritos
  const cargarInscritos = (passtoken, passlastKey) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    // Se consulta el lasttoken del parametro
    const lastKey = passlastKey;

    // trae los usuarios inscritos
    if (lastKey === undefined) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_REST_API_SOURCE +
          "user/?status=1&limit=40&order=desc",
        headers: {
          Authorization: token,
        },
      };
    } else {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_REST_API_SOURCE +
          "user/?status=1&limit=40&order=desc&lastKey=" +
          lastKey,
        headers: {
          Authorization: token,
        },
      };
    }

    axios(config)
      .then(function (response) {
        const newobject = response.data.items;
        const lastkey = response.data.lastKey;

        if (lastKey === undefined) {
          dispatch({
            type: CARGAR_INSCRITOS,
            payload: newobject,
            valuelastkey: lastkey,
          });
        } else {
          const newload = response.data.items;

          const predata = state.inscritos;

          const newdata = [...predata, ...newload];

          dispatch({
            type: RECARGAR_INSCRITOS,
            payload: newdata,
            valuelastkey: lastkey,
          });
        }
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Inscrito Actual
  const inscritoActual = (passtoken, userid) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url: process.env.REACT_APP_REST_API_SOURCE + "user/get/" + userid,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
        const newobject = response.data.items;
        dispatch({
          type: INSCRITO_ACTUAL,
          payload: newobject,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Editar usuarios
  const editarUser = (
    passtoken,
    documentType,
    documentNumber,
    documentIssueDate,
    documentExpiryDate,
    visaType,
    visaNumber,
    nationality,
    name,
    surname,
    email,
    place,
    address,
    cellphone,
    status,
    id
  ) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var data = JSON.stringify({
      documentType: documentType,
      documentNumber: documentNumber,
      documentIssueDate: documentIssueDate,
      documentExpiryDate: documentExpiryDate,
      visaType: visaType,
      visaNumber: visaNumber,
      nationality: nationality,
      name: name,
      surname: surname,
      email: email,
      place: place,
      address: address,
      cellphone: cellphone,
      status: status,
    });
    var config = {
      method: "put",
      url: process.env.REACT_APP_REST_API_SOURCE + "user/update/" + id,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        /* console.log(JSON.stringify(response.data)); */
      })
      .catch(function (error) {
        /* console.log(error); */
      });

    dispatch({
      type: EDITAR_INSCRITO,
    });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });

      dispatch({
        type: CLOSE_MODAL_EDICION_GUARDADA,
      });
    }, 3000);
  };

  // Buscar inscritos email
  const buscarInscritosEmail = (passtoken, email) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "user/?status=1&limit=40&order=" +
        "&email=" +
        email,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        const newobject = response.data.items;
        const lastkey = response.data.lastKey;
        /* console.log(JSON.stringify(response.data)); */
        dispatch({
          type: CARGAR_INSCRITOS,
          payload: newobject,
          valuelastkey: lastkey,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Buscar inscritos nombre
  const buscarInscritosNombre = (passtoken, name) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "user/?status=1&limit=40&order=" +
        "&fullname=" +
        name,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        const newobject = response.data.items;
        const lastkey = response.data.lastKey;

        dispatch({
          type: CARGAR_INSCRITOS,
          payload: newobject,
          valuelastkey: lastkey,
        });
      })
      .catch(function (error) {
        /* console.log(error); */
      });
  };

  // Buscar inscritos documento
  const buscarInscritosDocumento = (passtoken, number) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var config = {
      method: "get",
      url:
        process.env.REACT_APP_REST_API_SOURCE +
        "user/?status=1&limit=40&order=" +
        "&documentNumber=" +
        number,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then(function (response) {
        const newobject = response.data.items;
        const lastkey = response.data.lastKey;

        dispatch({
          type: CARGAR_INSCRITOS,
          payload: newobject,
          valuelastkey: lastkey,
        });
      })
      .catch(function (error) {
        /*  console.log(error); */
      });
  };

  // Eliminar inscrito
  const eliminarUser = (userId, passtoken) => {
    // Se consulta el token pasado por parametro
    const token = passtoken;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_REST_API_SOURCE + "user/delete/" + userId,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    dispatch({
      type: ELIMINAR_INSCRITO,
    });

    setTimeout(() => {
      dispatch({
        type: STOP_RELOAD,
      });
    }, 3000);
  };

  // Abrir el modal de editar inscrito
  const openModalEditarInscrito = () => {
    dispatch({
      type: OPEN_MODAL_EDITAR_INSCRITO,
    });
  };

  // Abrir el modal de aliminar inscrito
  const openModalEliminarInscrito = () => {
    dispatch({
      type: OPEN_MODAL_ELIMINAR_INSCRITO,
    });
  };

  // Edicion guardada
  const closeModalEdicionGuardada = () => {
    dispatch({
      type: CLOSE_MODAL_EDICION_GUARDADA,
    });
  };

  // Crear dispatch y state
  const [state, dispatch] = useReducer(inscritoReducer, initialState);

  return (
    <inscritoContext.Provider
      value={{
        inscritos: state.inscritos,
        inscritoactual: state.inscritoactual,
        actioninscritos: state.actioninscritos,
        lastkey: state.lastkey,
        errorformulario: state.errorformulario,
        numeroinscritos: state.numeroinscritos,
        getModalEliminarInscrito: state.getModalEliminarInscrito,
        getModalEditarInscrito: state.getModalEditarInscrito,
        getModalEdicionGuardada: state.getModalEdicionGuardada,

        cargarInscritos,
        editarUser,
        inscritoActual,
        eliminarUser,
        buscarInscritosEmail,
        buscarInscritosNombre,
        buscarInscritosDocumento,
        openModalEditarInscrito,
        openModalEliminarInscrito,
        closeModalEdicionGuardada,
        mostrarError,
        mostrarErrorEmail,
      }}
    >
      {props.children}
    </inscritoContext.Provider>
  );
};

export default InscritoState;
