import React, { Fragment, useContext, useState } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";
import ItemContext from "../../context/items/itemContext";
import encuestasContext from "../../context/encuestas/encuestaContext";

const EditarItem = () => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer encuesta del state inicial
  const encuestaContext = useContext(encuestasContext);
  const { encuestaactual } = encuestaContext;

  // Obtener el state de encuestas context
  const itemsContext = useContext(ItemContext);
  const {
    errorformulario,
    itemactual,

    editarItem,
    closeModalEditarItems,
  } = itemsContext;

  // State para encuesta
  const [item, guardarItem] = useState({
    label: itemactual.label,
    peso: itemactual.weight.toString(),
    imagen: itemactual.imagen,
    idencuesta: encuestaactual.id,
    iditem: itemactual.id,
  });

  // Extraer nombre de encuesta
  const { label, peso, idencuesta, iditem } = item;

  // Lee los contenidos del input
  const onChangeItem = (e) => {
    if (e.target.name === "imagen") {
      guardarItem({
        ...item,
        [e.target.name]: e.target.files[0],
      });
    } else {
      guardarItem({
        ...item,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Cuando el usuario envia un encuesta
  const onSubmitItem = (e) => {
    e.preventDefault();

    // Agregar al state
    editarItem(item, token, idencuesta, iditem);

    // Reiniciar el form
    guardarItem({
      label: "",
      peso: "",
      imagen: "",
      idencuesta: "",
      iditem: "",
    });

    /* closeModalEditarItems(); */
  };

  return (
    <Fragment>
      <h4>
        Editar Item: "{itemactual.label}", de la encuesta:{" "}
        {encuestaactual.title}
      </h4>
      <form className="formulario-nuevo-proyecto" onSubmit={onSubmitItem}>
        <input
          type="text"
          className="input-text"
          placeholder={itemactual.label}
          name="label"
          value={label}
          onChange={onChangeItem}
        />
        <hr></hr>
        <p>Imagen actual</p>
        <img
          src={"https://unicef.caracoltv.com/" + itemactual.image}
          alt="item.label"
        />
        <hr></hr>
        <input
          name="imagen"
          type="file"
          src={"https://unicef.caracoltv.com/" + itemactual.image}
          alt="Submit"
          accept="image/png, image/jpeg"
          onChange={onChangeItem}
        ></input>

        <p>Seleccione el peso del item, peso actual: {itemactual.weight}</p>
        <label htmlFor="activo">1</label>
        <input
          type="radio"
          id="one"
          name="peso"
          value="1"
          onChange={onChangeItem}
          checked={peso === "1"}
        />

        <label htmlFor="activo">2</label>
        <input
          type="radio"
          id="two"
          name="peso"
          value="2"
          onChange={onChangeItem}
          checked={peso === "2"}
        />

        <label htmlFor="activo">3</label>
        <input
          type="radio"
          id="three"
          name="peso"
          value="3"
          onChange={onChangeItem}
          checked={peso === "3"}
        />

        <label htmlFor="activo">4</label>
        <input
          type="radio"
          id="four"
          name="peso"
          value="4"
          onChange={onChangeItem}
          checked={peso === "4"}
        />

        <label htmlFor="activo">5</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="5"
          onChange={onChangeItem}
          checked={peso === "5"}
        />

        <label htmlFor="activo">6</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="6"
          onChange={onChangeItem}
          checked={peso === "6"}
        />

        <label htmlFor="activo">7</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="7"
          onChange={onChangeItem}
          checked={peso === "7"}
        />

        <label htmlFor="activo">8</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="8"
          onChange={onChangeItem}
          checked={peso === "8"}
        />

        <label htmlFor="activo">9</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="9"
          onChange={onChangeItem}
          checked={peso === "9"}
        />

        <label htmlFor="activo">10</label>
        <input
          type="radio"
          id="five"
          name="peso"
          value="10"
          onChange={onChangeItem}
          checked={peso === "10"}
        />

        <br></br>
        <br></br>
        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Guardar Item"
        />

        <input
          type="submit"
          className="btn btn-primario btn-block"
          value="Cerrar Edición"
          onClick={() => closeModalEditarItems()}
        />
        {errorformulario ? (
          <p className="mensaje error">Todos los campos son obligatorios</p>
        ) : null}
      </form>
    </Fragment>
  );
};

export default EditarItem;
