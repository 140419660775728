import React from "react";
// Import Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/auth/Login";
import NuevaCuenta from "./components/auth/NuevaCuenta";
import Eventos from "./components/eventos/Eventos";
import Inscritos from "./components/inscritos/Inscritos";
import Rifas from "./components/rifa/Rifas";

// Importacion de los diferentes context
import EventoState from "./context/eventos/eventoState";
import UsuarioState from "./context/usuarios/usuarioState";
import AlertaState from "./context/alertas/alertaState";
import EncuestaState from "./context/encuestas/encuestaState";
import ItemState from "./context/items/itemState";
import SorteoState from "./context/sorteos/sorteosState";
import RifaState from "./context/rifa/rifaState";
import EstadisticaState from "./context/estadisticas/estadisticasState";
import InscritoState from "./context/inscritos/inscritosState";

// Importacion de componentes
import RutaPrivada from "./components/rutas/RutaPrivada";

function App() {
  return (
    <UsuarioState>
      <EventoState>
        <EncuestaState>
          <ItemState>
            <RifaState>
              <SorteoState>
                <AlertaState>
                  <EstadisticaState>
                    <InscritoState>
                      <Router>
                        <Routes>
                          <Route path="/" element={<Login />} />
                          <Route
                            path="/nueva-cuenta"
                            element={<NuevaCuenta />}
                          />
                          <Route
                            path="/administrador"
                            element={<RutaPrivada component={Eventos} />}
                          />
                          <Route
                            path="/inscritos"
                            element={<RutaPrivada component={Inscritos} />}
                          />
                          <Route
                            path="/rifa/:eventoactual/:nombreactual"
                            element={<RutaPrivada component={Rifas} />}
                          />
                        </Routes>
                      </Router>
                    </InscritoState>
                  </EstadisticaState>
                </AlertaState>
              </SorteoState>
            </RifaState>
          </ItemState>
        </EncuestaState>
      </EventoState>
    </UsuarioState>
  );
}

export default App;
