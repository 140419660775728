import React, { useContext } from "react";

// Importando el context de las dependencias
import UsuarioContext from "../../context/usuarios/usuarioContext";

// Importando el context de las dependencias
import InscritosContext from "../../context/inscritos/inscritosContext";

const EliminarInscrito = ({ FCloseModalEliminarInscrito, inscritoactual }) => {
  // Extraer token del usuario del state inicial
  const usuariosContext = useContext(UsuarioContext);
  const { token } = usuariosContext;

  // Extraer data de los inscritos del state inicial
  const inscritosContext = useContext(InscritosContext);
  const { eliminarUser } = inscritosContext;

  const onClickEliminar = (identificador) => {
    eliminarUser(identificador, token);
  };

  const onClickCerrar = () => {
    FCloseModalEliminarInscrito();
  };

  return (
    <div className="listado-eventos">
      <div className="modal-eliminar-encuestas">
        <h3>
          Esta seguro de eliminar este usuario?:<br></br> Nombre del usuario:{" "}
          {inscritoactual.name} {inscritoactual.surname}
        </h3>

        <button
          type="button"
          className="btn alerta-error"
          onClick={() => onClickEliminar(inscritoactual.iduser)}
        >
          Eliminar Definitivamente
        </button>

        <button type="button" className="btn" onClick={() => onClickCerrar()}>
          Salir
        </button>
      </div>
    </div>
  );
};

export default EliminarInscrito;
